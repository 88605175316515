import React from "react";
import { get } from "lodash-es";

import employeeCreators from "farmerjoe-common/lib/actions/employee";

import Role from "./Role";
import Invite from "./Invite";

import type { Company, Employee as CompanyUser, User } from "../../flowTypes";
import Icon from "../Common/Icon";
import { Avatar } from "../Common/Avatar";
import OnlineIndicator from "./OnlineIndicator";
import CircleUrlButtonWithIcon from "../Common/CircleUrlButtonWithIcon";

type Props = {
  actions: {
    sendInvitation: typeof employeeCreators.sendInvitation;
    updateEmployee: typeof employeeCreators.updateEmployee;
    deleteEmployee: typeof employeeCreators.deleteEmployee;
    openEmployee: typeof employeeCreators.openEmployee;
  };
  user: CompanyUser & {
    profile: {
      avatar: {
        original: {
          downloadUrl: string;
        };
      };
    };
  };
  myCompanyProfile: CompanyUser;
  isOnline?: boolean;
  auth?: User;
  company: Company;
  selected?: boolean;
  history?: Record<string, any>;
};

const EmployeeContent = (props: Props) => {
  const { user, company, actions } = props;
  const invited: any = null;

  const avatarURL = get(user, "profile.avatar.original.downloadURL", null);

  return (
    <div style={{ flex: 1 }}>
      <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
        <div
          style={{ marginRight: 10, display: "flex", alignItems: "center" }}>
          <div style={{ position: "relative" }}>
            <Avatar
              downloadUrl={avatarURL}
              isLoaded={true}
              style={{ borderRadius: 30, backgroundColor: "#EEE" }}
              size={"medium"}
            />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <span style={{ fontWeight: "bold" }}>{user.name}</span>
                {invited
                  ? (
                    <Icon
                      name={"ios-mail-outline"}
                      style={{
                        fontSize: 25,
                        marginLeft: 5,
                        height: 20,
                        lineHeight: "22px",
                      }}
                    />
                  )
                  : null}
              </div>
              <Role user={user} />
            </div>
            <div>
              {user.phoneNumber
                ? (
                  <CircleUrlButtonWithIcon
                    url={`tel:${user.phoneNumber}`}
                    iconName={"ios-call"}
                  />
                )
                : null}
            </div>
          </div>
          <Invite user={user} actions={actions} company={company} />
        </div>
      </div>
    </div>
  );
};
export default EmployeeContent;
