import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { get } from "lodash-es";

import { getOpenCompanyId } from "farmerjoe-common/lib/selectors/selectors";

const CheckPermissionsComponent = (WrappedComponent) => {
  const CheckPermissions = (props) => {
    const { isPartOfCompany } = props;
    if (!isPartOfCompany) {
      return <Redirect to={"/unauthorized"} />;
    };
    return <WrappedComponent {...props} />;
  };
  const selector = (state, ownProps) => {
    const companyId = getOpenCompanyId(state) || ownProps.openKey;
    const isPartOfCompany = get(state, `firestore.data.userPermissions.${companyId}`, false);

    return {
      isPartOfCompany,
    };
  };
  return connect(selector)(CheckPermissions);
};



export default CheckPermissionsComponent;
