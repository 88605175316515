import React from "react";
import { get } from "lodash-es";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { displaySettings } from "farmerjoe-common/lib/actions/actions";
import { getOpenCompanyId } from "farmerjoe-common/lib/selectors/selectors";

import "./style.css";
import DateRangeFilterBase from "../DateRangeFilter/DateRangeFilter";

type Props = {
  openCompany: string;
  actions: Record<string, any>;
  date: string;
};

const DateRangeFilter = (props: Props) => {
  const { openCompany, actions, date } = props;

  const save = (value: string) => {
    actions.displaySettings(
      openCompany,
      "bonitur",
      {
        date: value,
      },
      true,
    );
  };
  return <DateRangeFilterBase onSave={save} date={date} />;
};

export default compose(
  connect(
    (state: any, ownProps) => {
      const openCompany = getOpenCompanyId(state);
      return {
        openCompany: openCompany,
        date: get(
          state,
          [
            "filtersByCompany",
            openCompany,
            "displaySettings",
            "bonitur",
            "date",
          ],
          "last:7",
        ),
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          displaySettings,
        },
        dispatch,
      ),
    }),
  ),
)(DateRangeFilter);
