import React, { useState, useRef } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";

import { openGroup } from "farmerjoe-common/lib/actions/group";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getCompany } from "farmerjoe-common/lib/selectors/companies";
import {
  COMPANY_MAIN_GROUP_KEY,
  getCompanyGroups,
  getOpenGroupId,
  getGroupSearchWord,
  getBrowsingGroupKey,
} from "farmerjoe-common/lib/selectors/groups";
import { getCompanyQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Companies";
import { getGroupsQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Groups";
import { filters } from "farmerjoe-common/lib/actions/actions";
import { isAdmin } from "farmerjoe-common/lib/utils/User";
import {
  getCompanyGroupProfileForLoggedInUser,
} from "farmerjoe-common/lib/selectors/user";

import Table, { GroupsTableColumnDefinition } from "./Table";
import { getExportFileName, getNoGroupItemsText } from "../utils";
import NavbarBasic from "../../Common/NavbarBasic";
import { CreateNewButton } from "../../Common/NewButton";
import SearchInput from "../../Common/SearchInput";
import Icon from "../../Common/Icon";
import NoResults from "../../Common/NoResults";
import { Loading } from "../../Loading/Loading";
import I18n from "../../../language/i18n";
import * as constants from "../../../styles/style";
import CreateGroup from "../../../containers/Groups/Form";
import type { Company, Group, Employee } from "../../../flowTypes";

/**
 * Typings for Props parsed from redux store
 */
type StateProps = {
  loading: boolean;
  openGroupId: string;
  email: string;
  myCompanyProfile: Employee;
  openCompany: string;
  company: Company;
  traders: Group[];

  /**
   * The search string entered in the search box
   */
  search: string;
};

/**
 * Typings for reducers from the redux store
 */
type DispatchProps = {
  actions: {
    openGroup: typeof openGroup;
    filters: typeof filters;
  };
};

type OwnProps = {
  history?: any[];
};

type Props = StateProps & DispatchProps & OwnProps;

function TradersTable(props: Props) {
  const { loading, search, openGroupId, traders, company, actions, myCompanyProfile } = props;
  const [isCreateFormVisible, showCreateForm] = useState(false);
  const tableRef = useRef<Table>(null);

  const renderCreateButtonIfAllowed = () => {
    if (!canEdit(myCompanyProfile)) return null;
    return <CreateNewButton onClick={() => showCreateForm(true)} />;
  };

  const renderCreateFormIfNeeded = () => {
    if (!isCreateFormVisible) return null;
    return <CreateGroup
      type="trader"
      show={isCreateFormVisible}
      onClose={() => showCreateForm(false)}
    />;
  };

  const onRowClick = (rowId: string) => {
    const { company, traders, actions, history } = props;
    const group = traders.find(group => group.key === rowId);
    if (!group) return;
    actions.openGroup(group.key);
    history?.push(
      `/company/${company.key}/trader/${group.key}`,
    );
  };

  const runSearch = text => actions.filters(company.key, { producerSearch: text } as any);

  if (loading) {
    return <Loading />;
  }

  const columns = getTradersTableColumnsDefinition();
  const tableRowData = mapTradersToTableRows(traders);

  return (
    <div className="producers-table">
      <div style={constants.styles.containerColumn}>
        <NavbarBasic
          title={I18n.t("traders.plural")}
          rightButton={renderCreateButtonIfAllowed()}
        />

        <div className="toolbar-container">
          <SearchInput
            onChange={runSearch}
            search={search}
          />
          <button
            className="btn btn-secondary column-config-button"
            onClick={() => tableRef.current?.exportData()}>
            <Icon iconType="fa" name="file-csv" style={{ fontSize: 20 }} />{" "}
            {I18n.t("export")}
          </button>
        </div>

        <div className={"scrollable-tab active"}>
          <Table
            ref={tableRef}
            loading={loading}
            emptyView={(<NoResults text={getNoGroupItemsText(search, "trader")} />)}
            onRowClick={onRowClick}
            exportFileName={getExportFileName("trader")}
            columns={columns}
            tableRowData={tableRowData}
            selectedRowId={openGroupId}
          />
        </div>

        {renderCreateFormIfNeeded()}
      </div>
    </div>
  );
}

function getTradersTableColumnsDefinition(): GroupsTableColumnDefinition[] {
  return [
    { name: "name", headerLabel: I18n.t("traders.name") },
    { name: "producerNumber", headerLabel: I18n.t("traders.number") },
    { name: "phoneNumber", headerLabel: I18n.t("mobile_phone") },
    { name: "email", headerLabel: I18n.t("email") },
    { name: "street", headerLabel: I18n.t("street") },
    { name: "city", headerLabel: I18n.t("city") },
    { name: "country", headerLabel: I18n.t("country") },
    { name: "zip", headerLabel: I18n.t("zip") },
    { name: "contactPersonName", headerLabel: I18n.t("name") },
    { name: "contactPersonTel", headerLabel: I18n.t("mobile_phone") },
    { name: "contactPersonEmail", headerLabel: I18n.t("email") },
  ];
}

function canEdit(myCompanyProfile) {
  return isAdmin(myCompanyProfile) &&
    myCompanyProfile.group_id === COMPANY_MAIN_GROUP_KEY;
}

const mapTradersToTableRows = (traders): Record<string, any>[] => {
  if (!Array.isArray(traders)) return [];

  return traders.map(group => ({
    id: group.key,
    name: group.name,
    phoneNumber: group.tel,
    email: group.email,
    street: group.street,
    city: group.city,
    country: group.country,
    zip: group.zip,
    producerNumber: group.group_number,
    contactPersonName: group.contact?.name,
    contactPersonEmail: group.contact?.email,
    contactPersonTel: group.contact?.tel,
  }));
};

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          openGroup,
          filters,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state, ownProps): StateProps => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const search = getGroupSearchWord(state, openCompany);
  const type = ownProps.type;

  const traders = getCompanyGroups(state, openCompany, type, browsingGroup);
  const openGroupId = getOpenGroupId(state);

  const hasLoadedEverything = hasLoaded(
    [getGroupsQuery(openCompany, myCompanyProfile, type)],
    state,
  );

  return {
    loading: !hasLoadedEverything,
    openGroupId,
    email: state.firebase.profile.email,
    myCompanyProfile,
    openCompany,
    company,
    traders,
    search,
  };
};

const wrappedGroups = firestoreConnect(props => {
  const { email, openCompany, type, myCompanyProfile } = props;
  if (!email) return [];

  return [
    getCompanyQuery(openCompany),
    getGroupsQuery(openCompany, myCompanyProfile, type),
  ];
})(TradersTable);

export default compose<any>(
  connect<StateProps, DispatchProps, OwnProps>(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(wrappedGroups);
