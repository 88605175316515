import React from "react";
import { get } from "lodash-es";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { getProfileQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Profiles";

import { Avatar as ComponentAvatar } from "../../components/Common/Avatar";

type Props = {
  uid: string;
  downloadUrl: string;
  requested: boolean;
};

const Avatar = (props) => {
  const { requested } = props;

  return (
    <ComponentAvatar
      isLoaded={requested}
      {...props}
    />
  );
};

const selector = (state, ownProps) => {
  const { uid } = ownProps;

  const downloadUrl = get(
    state.firestore.data,
    ["profiles", uid, "avatar", "original", "downloadURL"],
    null,
  );

  const requested = get(state.firestore, "status.requested", {});

  return {
    uid: uid,
    downloadUrl: downloadUrl,
    requested: requested[`profiles/${uid}`],
  };
};

const wrappedAvatar = firestoreConnect(props => {
  if (!props.uid) {
    return [];
  }

  return [
    getProfileQuery(props.uid),
  ];
})(Avatar);

export default connect(
  selector,
)(wrappedAvatar);
