import React from "react";
import { classes } from "../../../../utils/dom";
import "./style.css";

type Props = {
  schema?: any;
  style?: any;
  width?: any;
  columnClassName?: any;
  columnIndex?: any;
};

export default class BoniturHeaderCell extends React.Component<Props> {
  div = React.createRef<any>();

  componentDidMount() {
    this.updateTitleAttribute();
  }

  componentDidUpdate() {
    this.updateTitleAttribute();
  }

  updateTitleAttribute() {
    const el = this.div.current;
    if (el) {
      el.setAttribute("title", el.textContent);
    }
  }

  render() {
    const { schema, style, width, columnClassName, columnIndex } = this.props;
    return (
      <div
        className={classes(
          "header-cell",
          columnClassName,
          columnIndex === 0 && "cell-column-0",
          "no-sort",
          "bonitur-header-cell",
        )}
        style={{ ...style, width }}
        ref={this.div}>
        <span className="header-title">{schema.name}</span>
        <span className={classes("sorting", "sorting-none")} />
      </div>
    );
  }
}
