import React from "react";
import { compose } from "redux";

import TableFilter from "./TableFilter";
import DateRangeFilter from "../DateRangeFilter";
import FieldsViewSwitch from "../FieldsViewSwitch";
import Icon from "../../Common/Icon";
import SearchInput from "../../Common/SearchInput";
import { CreateNewButton } from "../../Common/NewButton";
import { DefaultTabBar } from "../../Common/ScrollableTabView";
import withRouter from "../../Router/withRouter";
import I18n from "../../../language/i18n";
import "./style.css";

// TODO: improve typings
type Props = any;
type State = any;

const TabBar = (props) => {
  const {
    showNew,
    onShowCreateForm,
    search,
    company,
    onSearch,
    onColumnConfigClick,
    tab,
    history,
    location,
    isMapPage,
    onExportClick,
    onClickOnDisableGrouping,
    groupingDisabled,
    ...restProps
  } = props;
  return (
    <>
      <div className="page-card-top-bar-container">
        <FieldsViewSwitch key="fields-view-switch" />
        <DefaultTabBar {...restProps} />
        {showNew
          ? (
            <CreateNewButton onClick={onShowCreateForm} />
          )
          : (
            <div style={{ width: 35 }}></div>
          )}
      </div>
      <div className="toolbar-container" style={{justifyContent: "space-between"}}>
        {tab === "harvested" ? <DateRangeFilter /> : null}
        {tab === "active" && !isMapPage ? <TableFilter /> : null}
        {!isMapPage
          ? (
            <SearchInput
              onChange={onSearch}
              search={search}
            />
          )
          : null}
        <div>
          <button
            className="btn btn-secondary column-config-button"
            style={{ marginRight: "5px"}}
            onClick={onColumnConfigClick}>
            <Icon iconType="fa" name="cogs" style={{ fontSize: 20 }} />{" "}
            {I18n.t("columnConfigurator.title")}
          </button>
          <button
            className="btn btn-secondary column-config-button"
            style={{ marginRight: "5px"}}
            onClick={onExportClick}>
            <Icon iconType="fa" name="file-csv" style={{ fontSize: 20 }} />{" "}
            {I18n.t("export")}
          </button>
          <button
            className="btn btn-secondary column-config-button"
            onClick={onClickOnDisableGrouping}>
            {groupingDisabled ? (
              <Icon iconType="fa" name="object-group" style={{ fontSize: 20 }} />
            ) : (
              <Icon iconType="fa" name="object-ungroup" style={{ fontSize: 20 }} />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default compose<typeof TabBar>(withRouter)(TabBar);
