import React, { Component } from "react";
import PropTypes from "prop-types";
import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";

import * as constants from "../../../styles/style";
import I18n from "../../../language/i18n";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { editField } from "farmerjoe-common/lib/actions/field";
import {
  deleteComment,
  editComment,
} from "farmerjoe-common/lib/actions/comment";
import { editWaitTime } from "farmerjoe-common/lib/actions/waitTime";
import { editFertilizing } from "farmerjoe-common/lib/actions/actions";
import { searchForFieldAmongTheUniverse } from "farmerjoe-common/lib/selectors/fields";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import ModalList from "../../Modal/ModalList";
import * as CommentUtils from "../../../utils/Comment";
import WaitTimeForm from "../../WaitTime/Form";
import CreateField from "../../Field/CreateField";
import FormFertilizing from "../../Fertilizers/Form";
import FormBonitur from "../../Bonitur/CreateBoniturForm";
import { AlertConfirmDialog } from "../../Dialog/Dialog";
import withRouter from "../../Router/withRouter";
import { openAnalysis } from "farmerjoe-common/lib/actions/analysis";
import { editForm } from "farmerjoe-common/lib/actions/form";
import copyToClipboard from "clipboard-copy";
import YieldForm from "../../Field/YieldForm";

const DIALOG_CONFIRM_RESULT = "ok";

// TODO: improve typings
type Props = any;
type State = any;

class Comment extends Component<Props, State> {
  static propTypes = {
    comment: PropTypes.object.isRequired,
  };

  state: any = {
    confirm: null,
    showCreateForm: false,
    showWaitTimeForm: false,
    showFertilizingForm: false,
    showBoniturForm: false,
    comment: null,
  };

  getData() {
    const { comment } = this.props;
    const data: any[] = [];

    // if (!CommentUtils.isSystem(comment.type)) {
    //   data.push({
    //     label:  I18n.t('share'),
    //     native: true,
    //     value:  'share'
    //   })
    // }
    //
    // We can't copy image comments currently
    if (
      !CommentUtils.typeIs("image", comment.type) &&
      !CommentUtils.typeIs("bonitur", comment.type)
    ) {
      data.push({
        label: I18n.t("copy"),
        value: "copy",
      });
    }

    // if (CommentUtils.typeIs('analysis', comment.type) && isAdmin(myCompanyProfile)) {
    //   data.push({
    //     label: I18n.t('analysis.analysis'),
    //     value: 'analysis'
    //   })
    // }

    if (this.showEditButton()) {
      data.push({
        label: I18n.t("edit"),
        value: "edit",
      });
    }

    if (this.showDeleteButton()) {
      data.push({
        label: I18n.t("delete"),
        labelStyle: { color: constants.FJAPPLERED },
        value: "onClickDelete",
        native: true,
      });
    }
    return data;
  }

  showEditButton() {
    const { comment, myCompanyProfile } = this.props;

    return CommentUtils.canEditComment(comment, myCompanyProfile);
  }

  showDeleteButton() {
    const { comment, myCompanyProfile } = this.props;
    return CommentUtils.canDeleteComment(comment, myCompanyProfile);
  }

  share() {
    // const {comment, field} = this.props
    //
    // share(comment, field.name)
  }

  render() {
    if (this.getData().length === 0) {
      return this.props.trigger;
    }

    return (
      <ModalList
        dropdownClassName="comment-menu"
        data={this.getData()}
        onClick={({ value }) => {
          if (this[value]) {
            this[value]();
          }
        }}
        stopPropagationOnTrigger={true}
        showMenuWhereClicked={true}
        showInPortal={true}>
        {this.props.trigger}
        {this.state.showCreateForm
          ? (
            <CreateField
              show={this.state.showCreateForm}
              onClose={() => this.setState({ showCreateForm: false })}
            />
          )
          : null}
        {this.state.comment && this.state.showWaitTimeForm
          ? (
            <WaitTimeForm
              comment={this.state.comment}
              show={this.state.showWaitTimeForm}
              onClose={() =>
                this.setState({ showWaitTimeForm: false, comment: null })
              }
            />
          )
          : null}

        {this.state.comment && this.state.showFertilizingForm
          ? (
            <FormFertilizing
              comment={this.state.comment}
              show={this.state.showFertilizingForm}
              onClose={() =>
                this.setState({ showFertilizingForm: false, comment: null })
              }
            />
          )
          : null}

        {this.state.comment && this.state.showYieldForm
          ? (
            <YieldForm
              comment={this.state.comment}
              show={this.state.showYieldForm}
              onClose={() =>
                this.setState({ showYieldForm: false, comment: null })
              }
            />
          )
          : null}

        {this.state.comment && this.state.showBoniturForm
          ? (
            <FormBonitur
              comment={this.state.comment}
              show={this.state.showBoniturForm}
              onClose={() =>
                this.setState({ showBoniturForm: false, comment: null })
              }
            />
          )
          : null}
        <AlertConfirmDialog
          {...this.state.confirm}
          show={!!this.state.confirm}
          key={4}
        />
      </ModalList>
    );
  }

  analysis() {
    const { comment } = this.props;
    this.props.actions.openAnalysis(comment.text.key);
    this.props.history.push(
      `/company/${this.props.openCompany}/analysis/${comment.text.key}`,
    );
  }

  copy() {
    const { comment } = this.props;
    copyToClipboard(this.formatCommentTextForSharing(comment));
  }

  edit() {
    const { comment, actions } = this.props;
    if (CommentUtils.isSystem(comment.type)) {
      if (CommentUtils.typeIs("crop.yield", comment.type)) {
        this.setState({ showYieldForm: true, comment });
        return;
      }

      if (
        CommentUtils.typeIs("field", comment.type) ||
        CommentUtils.typeIs("crop", comment.type)
      ) {
        actions.editField(comment.field_id);

        this.setState({ showCreateForm: true });
        return;
      }

      if (CommentUtils.typeIs("wait_time", comment.type)) {
        actions.editWaitTime({
          wait_time_id: comment.foreign_id || CommentUtils.getUid(comment.type),
          company_id: comment.company_id,
          field_id: comment.field_id,
          crop_id: comment.active_crop_uid,
        });

        this.setState({ showWaitTimeForm: true, comment });
        return;
      }

      if (CommentUtils.typeIs("fertilizing", comment.type)) {
        actions.editFertilizing(comment.text.key);

        this.setState({ showFertilizingForm: true, comment });
        return;
      }

      if (CommentUtils.typeIs("bonitur", comment.type)) {
        actions.editForm(comment.foreign_id);

        this.setState({ showBoniturForm: true, comment });
        return;
      }
    }

    actions.editComment(comment.key);
  }

  formatCommentTextForSharing(comment) {
    return CommentUtils.formatCommentTextForSharing(comment);
  }

  onClickDelete() {
    const { comment } = this.props;
    let title = I18n.t("comment.delete");
    let message = I18n.t("comment.doYouReallyWantToDelete");

    if (CommentUtils.typeIs("wait_time", comment.type)) {
      title = I18n.t("waittime.delete");
      message = I18n.t("waittime.doYouReallyWantToDelete");
    }

    if (CommentUtils.typeIs("fertilizing", comment.type)) {
      title = I18n.t("fertilizer.delete");
      message = I18n.t("fertilizer.doYouReallyWantToDelete");
    }

    if (CommentUtils.typeIs("bonitur", comment.type)) {
      title = "Bonitur löschen";
      message = "Wollen Sie wirklich die Bonitur löschen?";
    }

    this.setState({
      confirm: {
        title,
        children: message,
        buttons: [
          { label: I18n.t("cancel"), value: "cancel" },
          { label: I18n.t("yes"), value: DIALOG_CONFIRM_RESULT, className: "btn-danger" },
        ],
        onClose: result => {
          if (result === DIALOG_CONFIRM_RESULT) {
            this.delete(comment);
          }
          this.setState({ confirm: null });
        },
      },
    });
  }

  delete(comment) {
    const { actions } = this.props;

    actions.deleteComment(comment);
  }
}

const selector = state => {
  const user = state.firebase.profile;
  const openCompany = selectors.getOpenCompanyId(state);
  const openField = selectors.getOpenFieldId(state);

  const field = searchForFieldAmongTheUniverse(state, openCompany, user.uid, openField);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );

  return {
    myCompanyProfile,
    openCompany,
    field,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          editComment,
          deleteComment,
          editField,
          editWaitTime,
          editFertilizing,
          openAnalysis,
          editForm,
        },
      ),
      dispatch,
    ),
  };
};

export default compose<typeof Comment>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(Comment);
