import React from "react";

import { getColor } from "farmerjoe-common/lib/utils/Colors";

// TODO: improve typings
type Props = {
  color: string;
  style?: any;
  children: React.ReactNode;
};

const CropHeaderContainer = (props: Props) => {
  const { color, children, style } = props;

  return (
    <div
      {...props}
      className={"crop-header-container"}
      style={{
        // In the first versions we were storing the color HEX value in the db. Later we changed to strings
        // If the string is not in the array, then we are most probably dealing with a HEX value
        backgroundColor: getColor(color),
        ...style,
      }}>
      {children}
    </div>
  );
};

export default CropHeaderContainer;
