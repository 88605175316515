import React, { useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash-es";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import {
  getCompanyGroupProfileForLoggedInUser,
} from "farmerjoe-common/lib/selectors/user";
import { isAdmin } from "farmerjoe-common/lib/utils/User";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { isNotMainGroup } from "farmerjoe-common/lib/utils/firestoreRedux/Utils";
import { openGroup } from "farmerjoe-common/lib/actions/group";
import {
  COMPANY_MAIN_GROUP_KEY,
  getBrowsingGroupKey,
} from "farmerjoe-common/lib/selectors/groups";
import { UserRole } from "farmerjoe-common/lib/flow/types";
import { fieldSharedBySelector } from "farmerjoe-common/lib/selectors/fields";
import { isFieldOwner } from "farmerjoe-common/lib/utils/Field";
import { outputDate } from "farmerjoe-common";

import FieldInfoCoordinatesSubmenu from "./FieldInfoCoordinatesSubmenu";
import Icon from "../Common/Icon";
import AvatarName from "../Users/AvatarName";
import EmployeesOnField from "../../containers/Employees/EmployeesOnField";
import I18n from "../../language/i18n";
import * as constants from "../../styles/style";
import type { Employee, Field } from "../../flowTypes";
import useFieldCollaborators from "../../hooks/useFieldCollaboratos";
import useCompanyEmployees from "../../hooks/useCompanyEmployees";

type Props = {
  field: Field;
  onClick: (...args: Array<any>) => any;
  users: any[];
  open: boolean;
  myCompanyProfile: Employee;
  actions: {
    openGroup: typeof openGroup;
  };
  openCompany: string;
  browsingGroup: string;
  sharedBy?: any;
};

const rowLabel = {
  ...constants.styles.stdSize,
  ...constants.styles.strong,
  ...constants.styles.mutedLight,
  minWidth: 190,
  display: "inline-block",
};

const renderUsers = (users, onMoreUsersClick) => {
  if (!users || !users.length) {
    return null;
  }

  const _users = users.length > 5 ? users.slice(0, 5) : users;
  const advisor = _users.filter((user) => user.role === UserRole.Advisor);
  const normal = _users.filter((user) => user.role !== UserRole.Advisor);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {advisor.length ? (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span style={rowLabel}>{I18n.t("employees.advisors")}: </span>
          <div style={{ marginTop: 5 }}>
            {advisor.map((user) => {
              return (
                <div style={{ marginBottom: 10 }} key={user.key}>
                  <AvatarName
                    uid={user.key}
                    name={user.name}
                    groupId={user.group_id || COMPANY_MAIN_GROUP_KEY}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      <div
        style={{ display: "flex", flexDirection: "row", paddingBottom: 5 }}
      >
        <span style={rowLabel}>{I18n.t("employees.plural")}: </span>
        <div style={{ marginTop: 5 }}>
          {normal.map((user) => {
            return (
              <div style={{ marginBottom: 10 }} key={user.key}>
                <AvatarName
                  uid={user.key}
                  name={user.name}
                  groupId={user.group_id || COMPANY_MAIN_GROUP_KEY}
                  avatarUrl={get(user, "profile.avatar.original.downloadURL", null)}
                />
              </div>
            );
          })}
          { users.length > 5 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: 5,
                cursor: "pointer",
              }}
              onClick={onMoreUsersClick}
            >
              <span style={{fontSize: "14px", color: constants.FJAPPLEGREEN}}>{I18n.t("employees.all_employees")} </span>
            </div>
          ) : null }
        </div>
      </div>
    </div>
  );
};

const renderGroupInfo = (label, companyId, key, name, type, actions) => {
  return (
    <div style={{ flexDirection: "row", paddingBottom: 5 }}>
      <span style={rowLabel}>{label}: </span>

      <Link
        key={"producer"}
        to={`/company/${companyId}/${type}/${key}`}
        onClick={() => {
          actions.openGroup(key);
        }}
      >
        <span style={{ ...constants.styles.stdSize }}>{name}</span>
      </Link>
    </div>
  );
};

const renderDataRow = (label, data) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", paddingBottom: 5 }}>
      <span style={rowLabel}>{label}: </span>
      <span style={{ ...constants.styles.stdSize }}> {data}</span>
    </div>
  );
};

const renderOwnerInfo = (field, openCompany, sharedBy) => {
  if (!sharedBy) {
    return null;
  }

  const isOwnerOfField = isFieldOwner(field.company_id, openCompany);
  if (isOwnerOfField) {
    return null;
  }

  const name = sharedBy.company ? sharedBy.company.name : sharedBy.name;

  return (
    <div style={{ display: "flex", flexDirection: "row", paddingBottom: 5 }}>
      <span style={rowLabel}>
        {I18n.t("fieldSharing.fieldInfoModal.header")}:{" "}
      </span>
      <Link
        to={`/company/${openCompany}/field/${field.key}/collaborators/${sharedBy.company.key}`}
      >
        <span style={{ ...constants.styles.stdSize }}>{name}</span>
      </Link>
    </div>
  );
};

const renderSharedWith = (fieldCollaborators, openCompany, field) => {
  if (!fieldCollaborators.length) {
    return null;
  }

  const links = fieldCollaborators.map((fc: { key: string, name: string}, key) => {
    return (
      <div key={key} style={{ display: "flex", flexDirection: "row", paddingBottom: 5 }}>
        <Link
          to={`/company/${openCompany}/field/${field.key}/collaborators/${fc.key}`}
        >
          <span style={{ ...constants.styles.stdSize, marginRight: "0.5em" }}>{fc.name}</span>
        </Link>
      </div>
    );
  });

  return renderDataRow(I18n.t("fieldFiltersModal.showSharedWithCompanies"), links);
};

const renderExternalCropId = (field) => {
  if (!field) {
    return null;
  }

  const externalCropId = get(field, "activeCrop.externalCropId", null);
  if (!externalCropId) {
    return null;
  }

  return renderDataRow(I18n.t("crop.externalCropId"), externalCropId);
};

const FieldInfo = (props: Props) => {
  const { field, myCompanyProfile, openCompany, sharedBy, open, browsingGroup } = props;
  const runHook = get(field, "collaborators", false);
  const fieldCollaborators = useFieldCollaborators(field.collaborators as any, runHook);
  const [employees, loading] = useCompanyEmployees(openCompany, browsingGroup, "active");
  const [showEmployeesOnField, setShowEmployeesOnField] = useState(false);

  if (!open) {
    return null;
  }
  const producerKey = get(field, "groupMeta.key");
  const producerName = get(field, "groupMeta.name");
  const traderKey = get(field, "traderMeta.key");
  const traderName = get(field, "traderMeta.name");
  // if the currently logged user belongs to the main group and the field he looks at belongs to a producer
  const canViewProducer =
      myCompanyProfile &&
      !isNotMainGroup(myCompanyProfile.group_id) &&
      isNotMainGroup(producerKey);

  return (
    <div
      style={{
        zIndex: 1,
        backgroundColor: "transparent",
        boxShadow: "1px 0px 20px rgba(0, 0, 0, 0.08)",
      }}
    >
      <div
        style={{
          padding: 10,
          paddingTop: 20,
          borderBottomWidth: 1,
          borderBottomColor: constants.FJBORDERCOLOR,
          backgroundColor: "#FFF",
        }}
      >
        {renderExternalCropId(field)}
        {renderOwnerInfo(field, openCompany, sharedBy)}
        {renderSharedWith(fieldCollaborators, openCompany, field)}
        {canViewProducer &&
            renderGroupInfo(
              I18n.t("producers.single"),
              openCompany,
              producerKey,
              producerName,
              "producer",
              props.actions,
            )}
        {traderKey
          ? renderGroupInfo(
            I18n.t("traders.single"),
            openCompany,
            traderKey,
            traderName,
            "trader",
            props.actions,
          ): null }

        {(field as any).irrigation
          ? renderDataRow(
            I18n.t("irrigation.irrigationType"),
            I18n.t(`irrigation.types.${(field as any).irrigation}`),
          ) : null}

        {(field as any).waterOrigin
          ? renderDataRow(
            I18n.t("irrigation.waterOrigin"),
            I18n.t(
              `irrigation.waterOriginTypes.${(field as any).waterOrigin}`,
            ),
          ): null}

        {renderDataRow(I18n.t("crop.sown_on"), outputDate(field.activeCrop.sown_on))}

        <div
          style={{ display: "flex", flexDirection: "row", paddingBottom: 5 }}
        >
          <span style={rowLabel}>{I18n.t("coordinates")}: </span>
          <FieldInfoCoordinatesSubmenu field={field} />
        </div>

        {field.land_id
          ? renderDataRow(I18n.t("field.number"), field.land_id) : null}

        {field.notes
          ? renderDataRow(I18n.t("field.number"), field.notes) : null}

        {renderUsers(employees, () => setShowEmployeesOnField(true))}

        {isAdmin(myCompanyProfile) ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              className="btn btn-secondary"
              onClick={() => setShowEmployeesOnField(true)}
            >
              <Icon name={"md-person-add"} />{" "}
              {I18n.t("employees.addEmployee")}
            </button>
          </div>
        ) : null}
      </div>

      {showEmployeesOnField ? (
        <EmployeesOnField
          show={showEmployeesOnField}
          onClose={() => setShowEmployeesOnField(false)}
          employees={employees}
        />
      ) : null}
    </div>
  );
};

const selector = (state, ownProps) => {
  const openCompanyId = selectors.getOpenCompanyId(state);
  const browsingGroup = getBrowsingGroupKey(state, openCompanyId);
  const field = ownProps.field;
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompanyId,
  );
  const sharedBy = fieldSharedBySelector(
    state,
    openCompanyId,
    state.firebase.auth.uid,
    field.key,
  );

  return {
    field,
    myCompanyProfile,
    browsingGroup,
    openCompany: openCompanyId,
    sharedBy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          openGroup,
        },
      ),
      dispatch,
    ),
  };
};

export default connect(selector, mapDispatchToProps)(FieldInfo);
