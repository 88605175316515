import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { filters } from "farmerjoe-common/lib/actions/actions";
import { editEmployee } from "farmerjoe-common/lib/actions/employee";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import {
  getActiveFields,
  searchForFieldAmongTheUniverse,
} from "farmerjoe-common/lib/selectors/fields";
import { getCompany } from "farmerjoe-common/lib/selectors/companies";
import {
  getCompanyGroupProfileForLoggedInUser,
  userState,
} from "farmerjoe-common/lib/selectors/user";

import type { Employee, Field } from "../../flowTypes";
import EmployeesOnFieldComponent from "../../components/Employees/EmployeesOnField";

type Props = {
  loading: boolean;
  myCompanyProfile: Employee;
  actions: {
    editEmployee: (...args: Array<any>) => any;
  };
  field: Field;
  employees: any[];
};

const Employees = (props: Props) => {
  const { employees } = props;
  return <EmployeesOnFieldComponent {...props} users={employees} />;
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          filters,
          editEmployee,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state, ownProps) => {
  const user = state.firebase.profile;
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const fields = getActiveFields(state, openCompany, state.firebase.auth.uid);

  const openField = selectors.getOpenFieldId(state);
  const field = searchForFieldAmongTheUniverse(state, openCompany, user.id, openField);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  return {
    email: state.firebase.profile.email,
    myCompanyProfile,
    openCompany,
    company,
    fields,
    field,
    filter: userState(state, openCompany),
    auth: state.firebase.auth,
  };
};

export default connect(
  selector,
  mapDispatchToProps,
)(Employees);
