import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getFieldsView } from "farmerjoe-common/lib/selectors/fields";

import { TableListViewSwitch } from "../Common/TableListViewSwitch";
import { setFieldsView } from "../../actions/fieldTable";
import { TableListViewState } from "../../flowTypes";

type Props = {
  fieldsView: TableListViewState;
  openCompany: string;
  actions: {
    setFieldsView: (arg0: string, arg1: string) => void;
  };
};

const FieldsViewSwitch = ({
  fieldsView,
  openCompany,
  actions: { setFieldsView },
}: Props) => {

  return (
    <TableListViewSwitch
      currentState={fieldsView}
      onStateChange={(newViewState) => setFieldsView(openCompany, newViewState)}
    />
  );
};

export default compose(
  connect(
    (state: any) => {
      const openCompany = selectors.getOpenCompanyId(state);
      return {
        openCompany,
        fieldsView: getFieldsView(state, openCompany),
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          setFieldsView,
        },
        dispatch,
      ),
    }),
  ),
)(FieldsViewSwitch);
