import React from "react";

import TextInput from "./TextInput";
import Icon from "../Common/Icon";
import I18n from "../../language/i18n";
import * as constants from "../../styles/style";
import "./style.css";

type Props = {
  search: string;
  onChange?: (arg0: string) => void;
  autoFocus?: boolean;
  styleInput?: any;
  onChangeText?: (arg0: string) => void;
  autoCorrect?: string;
  placeholder?: string;
};

type State = {
  search: string;
};

/**
 * Display a search box for filtering the users in a company
 */
export default class SearchInput extends React.PureComponent<Props, State> {
  static defaultProps = {
    autoFocus: false,
  };

  state = {
    search: this.props.search,
  };

  isFocused = false;

  /**
   * We need to make sure we update the component, when the component is not focused,
   * but it is receiving new state
   *
   * @param prevProps
   * @param prevState
   */
  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.isFocused === false && this.props.search !== prevProps.search) {
      this.setState({ search: this.props.search });
    }
  }

  render() {
    const { autoFocus, onChange, search: searchProp, placeholder, ...restProps } = this.props;
    const { search } = this.state;

    return (
      <div className="search-input">
        <Icon
          name="search"
          iconType="fj"
          style={{
            color: constants.FJMUTEDDARK,
            fontSize: 15,
          }}
        />
        <TextInput
          type="text"
          autoFocus={autoFocus}
          placeholder={placeholder || I18n.t("search")}
          autoCapitalize="none"
          onChange={e => {
            const text = e.target.value;
            this.setState({ search: text }, () => {
              if (onChange) onChange(text);
            });
          }}
          onFocus={() => {
            this.isFocused = true;
          }}
          onBlur={() => {
            this.isFocused = false;
          }}
          value={search || ""}
          {...restProps}
        />
        {search
          ? (
            <span
              className="clear"
              onClick={() => {
                this.setState({ search: "" }, () => {
                  if (onChange) onChange("");
                });
              }}>
              <Icon
                name="md-close"
                style={{
                  color: constants.FJBORDERCOLOR,
                  fontSize: "1.4em",
                }}
              />
            </span>
          )
          : null}
      </div>
    );
  }
}
