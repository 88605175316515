import React, { useContext } from "react";

import type { Favorite } from "farmerjoe-common/lib/flow/types";

import TextInput from "./TextInput";
import Icon, { FjIconType } from "./Icon";
import Dialog from "../Dialog/Dialog";
import I18n from "../../language/i18n";

import * as favQuery from "../../data/queries/favorites";
import { DataContext } from "../../contexts/FavoritesProvider";
import { Loading } from "../Loading/Loading";

type Props = {
  favorites: Favorite[];
  onClick: (...args: Array<any>) => any;
  headerIcon?: string;
  headerText?: string;
  iconType?: FjIconType;
  simpleContent?: boolean;
  hasDeleteButton?: boolean;
  renderCustomRow?: (...args: Array<any>) => any;
  className?: string;
  iconStyle?: string;
  getCustomRowText?: (...args: Array<any>) => any;
  refreshData?: (...args: Array<any>) => any;
};
type State = {
  showDialog: boolean;
  search: string;
  loading: boolean;
};
class Favorites extends React.PureComponent<Props, State> {
  static propTypes = {};

  static get defaultProps() {
    return {
      simpleContent: true,
      headerIcon: "star",
      headerText: I18n.t("favorites"),
      iconType: "fal",
      // iconStyle:       {fontSize: 27, lineHeight: 1, display: 'inline-block'},
      hasDeleteButton: true,
    };
  }

  state = {
    showDialog: false,
    search: "",
    loading: false,
  };

  render() {
    const { headerIcon, headerText, iconType } = this.props;
    const showDialog = this.state.showDialog;

    return (
      <React.Fragment>
        <button
          className="fj-btn fj-btn-with-icon btn btn-primary"
          onClick={this.showDialog}>
          {headerIcon
            ? (
              <Icon name={headerIcon} iconType={iconType} iconStyle />
            )
            : null}{" "}
          {headerText}
        </button>

        {showDialog ? this.renderDialog() : null}
      </React.Fragment>
    );
  }

  renderDialog() {
    const {
      favorites,
      onClick,
      simpleContent,
      hasDeleteButton,
      getCustomRowText,
    } = this.props;

    const search = this.state.search || "";

    const filteredFavorites = search
      ? favorites.filter((f: any) =>
        String(simpleContent ? f.favoriteName : (getCustomRowText as any)(f))
          .toLowerCase()
          .includes(search.toLowerCase()),
      )
      : favorites;


    const renderRow = (rowData) => {
      if (simpleContent) {
        return <span>{rowData.favoriteName}</span>;
      }
      if (this.props.renderCustomRow) {
        return this.props.renderCustomRow(rowData);
      }
      return null;
    };

    return (
      <Dialog
        className="favorites"
        show={true}
        onClose={this.closeDialog}
        title={null}
        footer={
          <div>
            <button
              className="btn btn-secondary ml-auto"
              onClick={this.closeDialog}>
              {I18n.t("cancel")}
            </button>
          </div>
        }>
        <TextInput
          value={search}
          onChange={e => this.setState({ search: e.target.value })}
          placeholder={I18n.t("search")}
          autoFocus
        />
        {this.state.loading ? <Loading /> : (
          <ul className="list list-style-none">
            {filteredFavorites.length === 0
              ? I18n.t("noSearchResults")
              : filteredFavorites.map((rowData: any, rowId) => {
                return (
                  <li key={rowId}>
                    <div
                      className={"fav-label"}
                      onClick={() => {
                        onClick(rowData);
                        this.closeDialog();
                      }}>
                      {renderRow(rowData)}
                    </div>
                    {hasDeleteButton
                      ? (
                        <button
                          className="delete-button btn btn-danger"
                          onClick={() => {
                            this.setState({ loading: true });
                            return favQuery.remove(rowData).then(() => {
                              this.props.refreshData && this.props.refreshData();
                            }).finally(() => {
                              this.setState({ loading: false });
                            });
                          }}>
                          {I18n.t("delete")}
                        </button>
                      )
                      : null}
                  </li>
                );
              })}
          </ul>
        )}
      </Dialog>
    );
  }

  showDialog = () => {
    this.setState({ showDialog: true });
  };

  closeDialog = () => {
    this.setState({ showDialog: false, search: "" });
  };
}

const HOC = (props: Props) => {
  const { refreshData } = useContext(DataContext);
  return <Favorites {...props} refreshData={refreshData} />;
};

export default HOC;
