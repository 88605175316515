import {useEffect} from "react";
import { getUidToken } from "../utils/auth";

import { syncMeta } from "farmerjoe-common/lib/requests/stats";

const useMetaSync = (openKey: string | undefined) => {
  useEffect(() => {
    if (openKey) {
      getUidToken().then(uidToken => {
        syncMeta(uidToken, openKey);
      });
    }
  }, [openKey]);
};

export default useMetaSync;
