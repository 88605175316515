import React from "react";
import { getColor } from "farmerjoe-common/lib/utils/Colors";

type TProps = {
  precrops?: string[];
  precropsFromApi?: string[];
  onRemoveUserCrop?: (...args: any) => any;
};

const PrecropsFromField = ({ precrops = [], precropsFromApi = [], onRemoveUserCrop }: TProps) => {
  const _apiCrops = new Set(precropsFromApi);

  const userCrops = precrops
    .filter(p => !_apiCrops.has(p))
    .map((precrop, idx) => {
      return (
        <div key={idx + 8888} style={{
          borderRadius: "10px",
          backgroundColor: getColor("noCrop"),
          color: "#3D312E",
          padding: "0 10px",
          margin: "3px 1px",
        }}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <div>
              {precrop}
            </div>
            <div
              style={{
                marginLeft: "5px",
                fontSize: "16px",
                fontWeight: "bold",
                color: "#cbcbcb",
                cursor: "pointer",
              }}
              onClick={() => {
                if (onRemoveUserCrop) {
                  onRemoveUserCrop(precrop);
                }
              }}
            >
              <i className="fa fa-times" />
            </div>
          </div>
        </div>
      );
    });

  const userCropsFromApi = (precropsFromApi as any).map((precrop, idx) => {
    return (
      <div key={idx + 3333} style={{
        borderRadius: "10px",
        backgroundColor: getColor("noCrop"),
        color: "#3D312E",
        padding: "0 10px",
        margin: "3px 1px",
      }}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <div>
            {precrop}
          </div>
        </div>
      </div>
    );
  });
  return (
    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
      {userCrops}
      {userCropsFromApi}
    </div>
  );
};

export default PrecropsFromField;
