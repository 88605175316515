import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get } from "lodash-es";

import { searchForFieldAmongTheUniverse } from "farmerjoe-common/lib/selectors/fields";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { orderWaitTimesByFieldId } from "farmerjoe-common/lib/selectors/waittimes";
import { editField } from "farmerjoe-common/lib/actions/field";
import { getCropAge } from "farmerjoe-common/lib/utils/Crop";

import Icon from "../Common/Icon";
import Waittime from "../WaitTime/Waittime";
import Badge from "../FieldMark/Badge";

import * as constants from "../../styles/style";
import I18n from "../../language/i18n";
import "./style.css";

import useNumberFormatter from "../../hooks/useNumberFormatter";

// import type { Field, WaitTime } from 'farmerjoe-common'

const styles = {
  info: {
    ...constants.styles.extraSmall,
    color: constants.FJMUTED,
    marginRight: 15,
  },
};

type Props = {
  field: any; // Field
  waitTimes: any[]; // WaitTime[]
  leftButton: React.ReactNode;
  onClick: () => {};
  rightButton: React.ReactNode;
};

const Navbar = (props: Props) => {
  const { field, waitTimes } = props;
  const [open, setOpen] = useState(false);
  const numberFormatter = useNumberFormatter(I18n.locale, 0);


  if (!field) {
    return null;
  }

  const title = field.name;
  const { analysesCount } = props.field.activeCrop;

  const waitTime = waitTimes[field.key]
    ? (
      <Waittime
        key={"waittime"}
        iconStyle={{ fontSize: 11, padding: 0 }}
        allowFontScaling={false}
        waitTimes={waitTimes[field.key]}
      />
    )
    : null;

  const pieces = get(field, "activeCrop.pieces");
  const subtitleFieldSize = pieces ? (
    <div>
      <span key="ha" style={styles.info}>
        {field.size || 0} ha 
      </span>
      <span key="pieces" style={styles.info}>
        {numberFormatter(pieces)} {I18n.t("crop.pieces.short")} 
      </span>
    </div>
  ) : (
    <span key="ha" style={styles.info}>
      {field.size || 0} ha
    </span>
  );

  let subtitle = [
    <span key="ha" style={styles.info}>
      {subtitleFieldSize}
    </span>,
    <span key="sown_on" style={{ ...styles.info, marginRight: 20 }}>
      {I18n.t("x_days", { days: getCropAge(field.activeCrop) })}
    </span>,
    <Icon
      key="info"
      name={"ios-information-circle-outline"}
      style={{
        marginRight: 20,
        fontSize: 16,
        marginTop: 3,
        color: constants.FJMUTED,
      }}
    />,
  ];

  if (analysesCount) {
    subtitle = [
      ...subtitle,
      <div key="analysis">
        <span style={styles.info}>
          <Icon
            name="analysis"
            iconType={"fj"}
            style={{ fontSize: 10, color: constants.FJMUTED }}
          />{" "}
          {analysesCount}
        </span>
      </div>,
    ];
  }

  if (waitTime) {
    subtitle = [...subtitle, waitTime];
  }


  const renderMark = () => {
    const mark = props.field.activeCrop.mark;
    if (mark) {
      const reason = get(mark, "reason");
      return (
        <span className="badge-container" title={reason || ""}>
          <Badge mark={mark} />
        </span>
      );
    } else {
      return null;
    }
  };
  return (
    <div
      className="page-card-top-bar-container field-navbar"
      style={{ ...constants.styles.navBar }}>
      {props.leftButton}
      <div
        className={"page-card-top-bar-title-container d-flex flex-column"}
        onClick={() => {
          setOpen(!open);
          props.onClick();
        }}>
        <div
          className={
            "page-card-top-bar-title d-flex flex-row justify-content-center align-items-center"
          }>
          {renderMark()}

          <span className={"page-card-top-bar-title-text"}>{title}</span>
        </div>
        <div
          className={
            "page-card-top-bar-subtitle d-flex flex-row justify-content-center align-items-center"
          }>
          {subtitle}
        </div>
      </div>
      {props.rightButton}
    </div>
  );

};

const selector = (state, ownProps) => {
  const user = state.firebase.profile;
  const openCompanyId = selectors.getOpenCompanyId(state);
  const openField = selectors.getOpenFieldId(state);
  const field =
    ownProps.field || searchForFieldAmongTheUniverse(state, openCompanyId, user.uid, openField);
  return {
    field,
    waitTimes: orderWaitTimesByFieldId(state, field.company_id),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          editField,
        },
      ),
      dispatch,
    ),
  };
};

export default connect(
  selector,
  mapDispatchToProps,
)(Navbar as any);
