import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { invert, get } from "lodash-es";

import { filters } from "farmerjoe-common/lib/actions/actions";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import {
  DISPLAY_SETTINGS_SHOW_CROPS_WITH,
  DISPLAY_SETTINGS_SHOW_CROPS_WITH_AREA,
  DISPLAY_SETTINGS_SHOW_CROPS_WITH_PIECES,
  MARKER_TITLE_HA,
  MARKER_TITLE_PIECES,
  MARKER_TITLE_CROP_AGE,
  MARKER_TITLE_WAIT_TIME,
  MARKER_TITLE_FIELD_NAME,
} from "farmerjoe-common/lib/constants/filters";

import SegmentedControl from "../../tcomb/templates/SegmentedControl";
import I18n from "../../language/i18n";

type Props = {
  actions: Record<string, any>;
  openCompany: string;
  filter: Record<string, any>;
  showCropPieces?: boolean;
};

type State = {
  markerTitle: string;
};

const defaultFilter = {
  markerTitle: MARKER_TITLE_HA,
};

const getMarkerTitles = (showCropPieces: boolean | undefined) => {
  if (showCropPieces) {
    return {
      [I18n.t("crop.pieces.long")]: MARKER_TITLE_PIECES,
      [I18n.t("crop.age")]: MARKER_TITLE_CROP_AGE,
      [I18n.t("waittime.waitTimeShort")]: MARKER_TITLE_WAIT_TIME,
      [I18n.t("field.name")]: MARKER_TITLE_FIELD_NAME,
    };
  }
  return {
    ha: MARKER_TITLE_HA,
    [I18n.t("crop.age")]: MARKER_TITLE_CROP_AGE,
    [I18n.t("waittime.waitTimeShort")]: MARKER_TITLE_WAIT_TIME,
    [I18n.t("field.name")]: MARKER_TITLE_FIELD_NAME,
  };
};

const MapMarkerTitles = (props: Props) => {
  const { filter, showCropPieces } = props;

  const [state, setState] = useState<State>({ markerTitle: showCropPieces ? MARKER_TITLE_PIECES : filter.markerTitle || MARKER_TITLE_HA});
  const [prevShowCropPieces, setPrevShowCropPieces] = useState<boolean | undefined>(showCropPieces);

  if (showCropPieces !== prevShowCropPieces) {
    setPrevShowCropPieces(showCropPieces);
    setState({ markerTitle: showCropPieces ? MARKER_TITLE_PIECES : filter.markerTitle || MARKER_TITLE_HA });
  }

  const markerTitles = getMarkerTitles(showCropPieces);

  const options = Object.keys(markerTitles);
  const selOptions = invert(markerTitles);

  return (
    <SegmentedControl
      className="map-marker-titles"
      options={options}
      containerStyle={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      optionContainerStyle={{
        display: "flex",
        justifyContent: "center",
        padding: "5px 10px",
        flexDirection: "column",
        height: 45,
      }}
      onChange={option => {
        const filters: any = {
          markerTitle: markerTitles[option],
        };
        if (
          markerTitles[option] !== MARKER_TITLE_CROP_AGE &&
            state.markerTitle === MARKER_TITLE_CROP_AGE
        ) {
          filters.cropAgeMin = null;
          filters.cropAgeMax = null;
        }

        setState({ markerTitle: markerTitles[option] });
        props.actions.filters(props.openCompany, filters);
      }}
      value={selOptions[state.markerTitle]}
      config={{ order: false }}
    />
  );
};

export default compose(
  connect(
    (state: any) => {
      const openCompany = selectors.getOpenCompanyId(state);
      
      const displaySettings = get(
        state,
        ["filtersByCompany", openCompany, "displaySettings"],
        {},
      );

      const currentFieldsTab = get(state, `currentFieldsTab[${openCompany}]`, null);

      const showCropPieces = get(
        displaySettings,
        [
          currentFieldsTab,
          DISPLAY_SETTINGS_SHOW_CROPS_WITH,
        ],
        DISPLAY_SETTINGS_SHOW_CROPS_WITH_AREA,
      ) === DISPLAY_SETTINGS_SHOW_CROPS_WITH_PIECES;

      return {
        openCompany,
        filter: state.filtersByCompany[openCompany]
          ? state.filtersByCompany[openCompany]
          : defaultFilter,
        showCropPieces,
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          filters,
        },
        dispatch,
      ),
    }),
  ),
)(MapMarkerTitles);
