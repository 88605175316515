import React, { useEffect } from "react";
import { firestoreConnect } from "react-redux-firebase";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { get } from "lodash-es";
import firebase from "firebase/app";

import { openCompany } from "farmerjoe-common/lib/actions/company";
import { getOpenCompanyId } from "farmerjoe-common/lib/selectors/selectors";
import { getCompany, activeInTempCompanies } from "farmerjoe-common/lib/selectors/companies";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import { getFeaturesObject } from "farmerjoe-common/lib/selectors/features";
import { getFieldSharingRequestsQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Fields";
import { getCompanyQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Companies";
import { getFeaturesQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Features";
import { getActivitiesSinceLastTime }  from "farmerjoe-common/lib/utils/firestoreRedux/Comments";
import { getCompanyMetaQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Meta";
import { getActivitySeenAt } from "farmerjoe-common/lib/selectors/latestActivities";

import UnreadCompanyNotifications from "./Notifications/UnreadCompanyNotifications";
import useCurrentUsage from "../components/Billing/useCurrentUsage";
import { Loading } from "../components/Loading/Loading";
import Page from "../components/Page/Page";
import withRouter from "../components/Router/withRouter";
import Companies from "../components/Companies/Companies";
import CompanyTitle from "../components/Page/CompanyTitle";
import { isIframe, isPrintPage } from "../utils/page";
import type { Profile } from "../flowTypes";
import useMetaSync from "../hooks/useMetaSync";
import CheckPermissions from "./CheckCompanyAccess";

type TCompanyLoaderProps = {
  openKey?: string;
  actions: {
    openCompany: typeof openCompany;
  };
  history?: Record<string, any>;
  restrictedAccess?: boolean;
  userIsMaster?: boolean;
  companyDoesntExist?: boolean;
  location?: any;
};

type Props = {
  children?: React.ReactNode;
  companies?: Record<string, any>;
  features?: Record<string, any>;
  profile?: Profile;
  dispatch?: (...args: Array<any>) => any;
  meta?: Record<string, any>;
  openCompany?: string;
  history?: Record<string, any>;
  noPage?: boolean;
  loading?: boolean;
  currentUsageLoading: boolean;
};

const CompanyLoaderWrapper = (props: TCompanyLoaderProps) => {
  const {
    openKey,
    history,
    actions,
    restrictedAccess,
    userIsMaster,
    companyDoesntExist,
    location,
  } = props;

  const [ currentUsage, currentUsageLoading ] = useCurrentUsage(openKey, false);

  useMetaSync(openKey);

  useEffect(() => {
    if (companyDoesntExist) {
      actions?.openCompany(null);
    }
    if (openKey) {
      actions?.openCompany(openKey);
    }
  }, [actions, openKey, companyDoesntExist]);

  if (redirectToFailed(userIsMaster, currentUsage, location, restrictedAccess)) {
    history?.push(`/company/${openKey}/failed-payment`);
  }

  if (props.companyDoesntExist) {
    history?.replace("/");
  }

  return (
    <CompanyLoader {...props} currentUsageLoading={currentUsageLoading} />
  );
};

const CompanyLoader = (props: Props) : any => {
  const { children, profile, noPage, loading, currentUsageLoading } = props;

  const isLoading = !profile?.isLoaded || loading || currentUsageLoading;

  if (!noPage && isLoading) {
    return (
      <Page
        before={[<Companies key={1} />]}
        title={<CompanyTitle />}
        wrapperClassName="d-flex align-content-center justify-content-center flex-column">
        <Loading isLoading={true} />
      </Page>
    );
  }
  return children;
};

const ConnectedWithStoreAndFirebase = compose<any>(
  connect(
    (state: any, ownProps: any) => {
      // XXX: `skipBilling` is used to overcome the billing restrictions for the 
      // master users viktor@farmerjoe.com, support@farmerjoe.com and start@farmerjoe.com
      // TODO: move the users onto the DB and use a helper function for the check
      const loggedInUserEmail = get(state, "firebase.auth.email");
      const userIsMaster = ["viktor@farmerjoe.com", "support@farmerjoe.com", "start@farmerjoe.com"].includes(loggedInUserEmail);

      const companyId = getOpenCompanyId(state) || ownProps.openKey;
      const company = getCompany(state.firestore.data, companyId);
      const isActiveInTempCompanies = activeInTempCompanies(state) || [];

      // XXX: Since the redux state is persisted, the next time the user
      // opens the browser, the date is not updated. That's why
      // we check if the last time the user was active is more recent
      // than the one in the redux state.
      const activitiesSeenAt = getActivitySeenAt(state, companyId);

      const paths = [
        getCompanyQuery(companyId),
        ...getFieldSharingRequestsQuery(companyId),
      ];

      if (activitiesSeenAt) {
        const { seconds, nanoseconds } = activitiesSeenAt || { seconds: 0, nanoseconds: 0 };
        paths.push(getActivitiesSinceLastTime(companyId, (new firebase.firestore.Timestamp(seconds, nanoseconds)).toDate()) as any);
      }

      if (!isActiveInTempCompanies.includes(companyId)) {
        paths.push(
          getFeaturesQuery(companyId),
        );
      }

      paths.push(getCompanyMetaQuery(companyId));

      return {
        companies: get(state, "firestore.data.companies"),
        meta: get(state, "firestore.data.meta"),
        features: getFeaturesObject(state),
        profile: get(state, "firebase.profile"),
        openCompany: companyId,
        companyDoesntExist:
          company === null && hasLoaded([`companies/${companyId}`], state),
        loading: !hasLoaded(
          paths,
          state,
        ),
        activeInTempCompanies: isActiveInTempCompanies,
        restrictedAccess: company ? (company.restrictedAccess || false) : false,
        userIsMaster,
        activitiesSeenAt,
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          openCompany,
        },
        dispatch,
      ),
    }),
  ),
  firestoreConnect(props => {
    // optimization for printing
    if (!props.loading && isIframe() && isPrintPage()) {
      return [];
    }

    const company = props.openKey; // keys(get(props, "profile.companies"))
    const paths: any = [];

    paths.push(
      getCompanyQuery(company),
      ...getFieldSharingRequestsQuery(company),
    );

    // if the user creates a company then the features query for this new company can fail
    // delay loading features until everything"s set up
    if (!props.activeInTempCompanies.includes(company)) {
      paths.push(getFeaturesQuery(company));
    }

    if (props.activitiesSeenAt) {
      const { seconds, nanoseconds } = props.activitiesSeenAt || { seconds: 0, nanoseconds: 0 };
      paths.push(getActivitiesSinceLastTime(props.openCompany, (new firebase.firestore.Timestamp(seconds, nanoseconds)).toDate()) as any);
    }

    paths.push(getCompanyMetaQuery(company));

    return paths;
  }),
  withRouter,
  UnreadCompanyNotifications,
)(CompanyLoaderWrapper);



const redirectToFailed = (userIsMaster, currentUsage, location, restrictedAccess) => {
  const allowRedirectToBilling = !!location.pathname.match("/billing") ||
    !!location.pathname.match("/employee") || 
    !!location.pathname.match("/info");

  if (!userIsMaster && (
    (((restrictedAccess || currentUsage.totalUsers > currentUsage.maxAllowedUsers) && !allowRedirectToBilling)))) {
    return true;
  }
  return false;
};

export default CheckPermissions(ConnectedWithStoreAndFirebase);
