import { useEffect, useState, useContext } from "react";

import fetch from "../utils/fetch";
import { getUidToken } from "../utils/auth";
import { BASE_API_URL } from "../constants";
import { DataContext } from "../contexts/FavoritesProvider";

const useFavorites = (companyId: string, favoritesType: string, browsingGroup: string) => {
  const [favorites, setFavorites] = useState([]);

  const { refreshTrigger } = useContext(DataContext);

  useEffect(() => {
    const controller = new AbortController();
    const fetchFavorites = async () => {
      try {
        const uidToken = await getUidToken();
        const response = await fetch(`${BASE_API_URL}/companies/${companyId}/favorites`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${uidToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({type: favoritesType, browsingGroup}),
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        const json = await response.json();
        setFavorites(json);
        return;
      } catch (e: any) {
        console.error("Unable to fetch favorites: ", e);
      }
    };
    fetchFavorites();
    return () => {
      controller.abort();
    };
  }, [companyId, favoritesType, browsingGroup, refreshTrigger]);

  return favorites;
};

export default useFavorites;
