import React from "react";
import { without, cloneDeep, get } from "lodash-es";
import t from "tcomb-form";

import ColorPicker from "../templates/ColorPicker";
import SegmentedControl from "../templates/SegmentedControl";
import ModalDate from "../templates/ModalDate";
import Favorite from "../templates/Favorite";
import FormInput from "../templates/FormInput";
import Expandable from "../../components/Common/Expandable";
import CalendarWeekDifference from "../../components/Field/CalendarWeekDifference";
import { dateTransformer, numberTransformer } from "../transformers/transformers";
import { defaultDateFormat } from "../utils/options";
import { latitude, longitude } from "../validators/coordinates";
import stylesheet from "../stylesheets/style";

import I18n from "../../language/i18n";
import * as constants from "../../styles/style";

const CALENDAR_WEEKS_COUNT = 52;

const tPosition = t.struct({
  latitude: latitude,
  longitude: longitude,
});

// Our crop model
const model = () => {
  return t.struct({
    precrops: t.maybe(t.struct({
      extraPrecrops: t.maybe(t.Array),
    })),
    name: t.String,
    art: t.maybe(t.String),
    pieces: t.maybe(t.Number),
    favorite: t.Boolean,
    color: t.String,
    sown_on: t.Date,
    activeSinceCalendarWeek: t.maybe(t.refinement(t.Number, (n) => n > 0 && n <= CALENDAR_WEEKS_COUNT)),
    type: t.enums({
      1: I18n.t("crop.temporary"),
      2: I18n.t("crop.permanent"),
    }),
    advancedSettings: t.maybe(t.struct({
      yieldPrediction: t.maybe(t.struct({
        estimatedAmount: t.Number,
        calendarWeek: t.refinement(t.Number, (n) => n > 0 && n <= CALENDAR_WEEKS_COUNT),
      })),
      markedArea: t.maybe(t.list(tPosition)),
    })),
  });
};

const shortModel = () => {
  return t.struct({
    art: t.maybe(t.String),
    pieces: t.maybe(t.Number),
    sown_on: t.Date,
    favorite: t.Boolean,
  });
};

const planModel = () => {
  return t.struct({
    precrops: t.maybe(t.struct({
      extraPrecrops: t.maybe(t.Array),
    })),
    name: t.String,
    art: t.maybe(t.String),
    pieces: t.maybe(t.Number),
    favorite: t.Boolean,
    color: t.String,
    activeSinceCalendarWeek: t.maybe(t.refinement(t.Number, (n) => n > 0 && n <= CALENDAR_WEEKS_COUNT)),
    type: t.enums({
      1: I18n.t("crop.temporary"),
      2: I18n.t("crop.permanent"),
    }),
    advancedSettings: t.maybe(t.struct({
      yieldPrediction: t.maybe(t.struct({
        estimatedAmount: t.Number,
        calendarWeek: t.refinement(t.Number, (n) => n > 0 && n <= CALENDAR_WEEKS_COUNT),
      })),
      markedArea: t.maybe(t.list(tPosition)),
    })),
  });
};

function template(locals, model?, CropNameComponent?) {
  return (
    <fieldset>
      {locals.inputs.precrops}
      <div className="d-flex form-crop-name">
        {model === "short" && CropNameComponent
          ? CropNameComponent
          : locals.inputs.name}
        {locals.inputs.favorite}
      </div>
      {without(locals.order, "favorite", "name", "precrops").map(
        field => locals.inputs[field],
      )}
    </fieldset>
  );
}

const options = function(defaults, model?, CropNameComponent?) {
  const formGroupOuter = cloneDeep(stylesheet);
  formGroupOuter.formGroup.normal.borderBottomWidth = 0;

  const styleColor = cloneDeep(stylesheet);
  styleColor.controlLabel.normal.paddingLeft = 20;
  styleColor.controlLabel.normal.marginTop = 20;

  styleColor.controlLabel.error.paddingLeft = 20;

  const formGroupOuterMargin = cloneDeep(formGroupOuter);
  formGroupOuterMargin.formGroup.normal.marginTop = 10;

  const cropId = cloneDeep(stylesheet);
  cropId.controlLabel.normal.marginTop = 10;
  cropId.controlLabel.normal.left = 0;
  cropId.controlLabel.normal.position = "relative";
  cropId.formGroup.normal.borderBottomWidth = 0;

  if (model === "short") {
    formGroupOuter.formGroup.normal.marginTop = 30;
    formGroupOuter.formGroup.normal.marginBottom = 50;
  }

  const { markAreaComponent } = defaults;

  return {
    stylesheet: stylesheet,
    auto: "none",
    template: locals => template(locals, model, CropNameComponent),
    fields: {
      active_crop_uid: {
        type: "hidden",
        label: "Kultur-ID",
        editable: false,
        stylesheet: cropId,
      },
      precrops: {
        template: locals => templatePrecrops(locals, defaults),
        fields: {
          extraPrecrops: {
            label: I18n.t("precrops.addMore"),
            placeholderTextColor: constants.FJLABEL,
            factory: FormInput,
            transformer: {
              format: (value) => {
                if (value instanceof Array) {
                  return value.join(", ");
                }
                return value;
              },
              parse: (value) => {
                if (!value) {
                  return null;
                }
                return value.split(", ");
              },
            },
          },
        },
      },
      name: {
        label: `${I18n.t("crop.name")} *`,
        factory: FormInput,
        placeholderTextColor: constants.FJLABEL,
        autoCorrect: false,
      },
      type: {
        label: `${I18n.t("crop.type")}`,
        nullOption: false,
        template: SegmentedControl,
        stylesheet: formGroupOuterMargin,
        autoCorrect: false,
        config: {
          help: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 5,
                marginBottom: 0,
                justifyContent: "space-between",
              }}>
              <span
                style={{
                  ...constants.styles.extraSmall,
                  ...{
                    textAlign: "center",
                    flex: 1,

                    color: constants.FJMUTEDLIGHT,
                  },
                }}>
                {I18n.t("grains_vegetables")}
              </span>
              <span
                style={{
                  ...constants.styles.extraSmall,
                  ...{
                    textAlign: "center",
                    flex: 1,
                    color: constants.FJMUTEDLIGHT,
                  },
                }}>
                {I18n.t("wine_fruit")}
              </span>
            </div>
          ),
        },
      },
      art: {
        label: `${I18n.t("crop.variety")}`,
        factory: FormInput,
        placeholderTextColor: constants.FJLABEL,
        autoCorrect: false,
      },
      pieces: {
        label: I18n.t("crop.pieces.long"),
        factory: FormInput,
        placeholderTextColor: constants.FJLABEL,
        autoCorrect: false,
      },
      color: {
        label: `${I18n.t("crop.select_color")} *`,
        factory: ColorPicker,
        stylesheet: styleColor,
      },
      sown_on: {
        label: `${I18n.t("crop.when_was_crop_planted")}`,
        maximumDate: new Date(),
        transformer: dateTransformer,
        config: {
          mode: "date",
          format: date => defaultDateFormat(date),
        },
        stylesheet: formGroupOuter,
        template: ModalDate,
      },
      field_id: {
        type: "hidden",
      },

      favorite: {
        config: {
          label: `${I18n.t("save_as_favorite")}`,
        },
        template: Favorite,
        hidden: !defaults.isOwnerOfField,
      },
      activeSinceCalendarWeek: {
        label: `${I18n.t("crop.cropActiveSince")} (${I18n.t("crop.calendarWeek")})`,
        factory: FormInput,
        transformer: numberTransformer,
      },
      advancedSettings: {
        label: I18n.t("advancedSettings"),
        template: locals => templateAdvancedSettings(locals, markAreaComponent),
        fields: {
          yieldPrediction: {
            label: I18n.t("crop.yieldPrediction"),
            template: locals => templateYieldPrediction(locals, defaults),
            fields: {
              estimatedAmount: {
                label: `${I18n.t("crop.estimatedAmount")} (t)`,
                transformer: numberTransformer,
                factory: FormInput,
              },
              calendarWeek: {
                label: I18n.t("crop.calendarWeek"),
                factory: FormInput,
                transformer: numberTransformer,
              },
            },
          },
        },
      },
    },
  };
};

const templateAdvancedSettings = (locals, markAreaComponent) => {
  return (
    <div>
      <Expandable label={locals.label}>
        <div>
          {markAreaComponent()}
        </div>
        <div style={{marginTop: "1em"}}>
          {locals.inputs.yieldPrediction}
        </div>
      </Expandable>
    </div>
  );
};

const templateYieldPrediction = (locals, defaults) => {
  const cropActiveSince = get(defaults, "crop.activeSinceCalendarWeek", null);
  const cropYieldedAt = get(defaults, "crop.advancedSettings.yieldPrediction.calendarWeek", null);

  return (
    <div style={{
      width: "90%",
      margin: "0 auto",
    }}>
      <Expandable label={locals.label}>
        <div>
          {locals.inputs.estimatedAmount}
        </div>
        <div>
          {locals.inputs.calendarWeek}
        </div>
        <CalendarWeekDifference
          cropActiveSince={cropActiveSince}
          cropYieldedAt={cropYieldedAt}
        />
      </Expandable>
    </div>
  );
};

const templatePrecrops = (locals, defaults) => {
  return (
    <div>
      <div>{I18n.t("precrops.precrops")}</div>
      {defaults.precropsComponent()}
      {locals.inputs.extraPrecrops}
    </div>
  );
};

// export let planModel = {planCropModel, options};
export default { model, shortModel, planModel, options };
