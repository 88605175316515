import React, { useState, useEffect, useCallback } from "react";
import { get } from "lodash-es";

import MapMarkers from "../Map/MapMarkers";
import { GoogleMap } from "../Map/Map";
import PolygonAndMarkerPlacement from "../Map/PolygonAndMarkerPlacement";
import MapControlOverlay from "../Map/MapControlOverlay";
import I18n from "../../language/i18n";

import {
  appPosToMapPos,
  getBounds,
  isValidPosition,
  latLngToAppPos,
} from "../../utils/Map";

type Props = {
  markers: any[];
  onChange: (...args: any) => any;
  currentMarkedArea: {
    polygon: any[];
    center: any;
  };
  position: any;
  polygon: any[];
  pinPosition: any;
  fitBounds?: any;
  polygonDrawing: any;
  markedAreaPolygons?: (...args: any) => any;
};

const DEFAULT_MAP_TYPE = "satellite";
const navigate = false;
const hideMarkers = true;
const hideResetFilter = true;
const hideDistanceMarkers = true;
const hideControls = false;
const showSearch = false;
const hideToolUi = true;
const displayFilter = false;
const displayGeoSearch = false;
const hideSearch = true;
const displayMarkers = false;

const MarkMapArea = (props: Props) => {
  const { markers: propMarkers, pinPosition, fitBounds: propFitBounds = true } = props;
  const markers = filterPin(propMarkers, pinPosition);

  const [fitBounds, setFitBounds] = useState(false);
  const [ mapRef, setMapRef ] = useState(null);
  const [ updatingMapPosition, setUpdatingMapPosition ] = useState(false);
  const [ userChangedView, setUserChangedView ] = useState(false);
  const [centerAndZoom, setCenterAndZoom] = useState(getCenterAndZoom(props, true));
  const [openMarker, setOpenMarker] = useState(undefined);
  const [ marker, setMarker] = useState(pinPosition);

  const onUserChangedView = useCallback(() => {
    if (!updatingMapPosition) {
      setUserChangedView(true);
    }
  }, [updatingMapPosition]);

  useEffect(() => {
    return () => {
      if (mapRef) {
        (mapRef as any).getDiv().removeEventListener("wheel", onUserChangedView);
      }
    };
  }, [mapRef, onUserChangedView]);

  const onMapRef = ref => {
    if (!mapRef) {
      setMapRef(ref);

      if (propFitBounds && !fitBounds) {
        let bounds;
        if (propFitBounds.getNorthEast) {
          bounds = propFitBounds;
        } else {
          const markerPositions = propMarkers.map(
            marker => marker.position,
          );
          if (markerPositions.length) {
            bounds = getBounds(markerPositions);
          }
        }
        if (bounds) {
          const map = ref;
          (window as any).google.maps.event.addListenerOnce(
            map,
            "zoom_changed",
            () => {
              if ((map?.getZoom() as number) > 20) {
                setUpdatingMapPosition(true);
                map?.setZoom(20);
                setUpdatingMapPosition(false);
              }
            },
          );
          ref.fitBounds(bounds);
        }
        setFitBounds(true);
      }
      ref
        .getDiv()
        .addEventListener("wheel", onUserChangedView, { passive: true });
    }
  };

  const onClick = () => {
    // Close an open marker
    if (openMarker) {
      setOpenMarker(undefined);
    }
  };

  const onMarkerPress = marker => {
    setOpenMarker(marker);
  };

  
  const onPolygonChange = value => {
    const { center, areaSize, polygon } = value;
    setMarker({
      marker: {
        ...marker,
        position: center || null,
      },
      areaSize,
      polygon,
    });
    // The parent needs to know about the change
    props.onChange(value);
  };

  return (
    <div>
      <div className="map-message-container">
        {pinPosition ? null : (
          <div className="map-message">
            <span>{I18n.t("maps.pressToSetPin")}</span>
          </div>
        )}
      </div>

      <GoogleMap
        mapRef={(ref) => onMapRef(ref)}
        mapContainerClassName="map-container"
        mapContainerStyle={{
          height: "calc(100vh - 60px - 56px - 86px - 2px)",
        }}
        options={{ gestureHandling: "greedy" }}
        defaultCenter={centerAndZoom.center}
        defaultZoom={centerAndZoom.zoom}
        onDrag={onUserChangedView}
        onClick={onClick}
        mapTypeId={DEFAULT_MAP_TYPE}
      >
        {(marker && marker.key) ||
          !mapRef ? null : (
            <MapMarkers
              map={mapRef}
              markers={markers}
              openMarker={openMarker}
              navigate={navigate}
              onMarkerPress={onMarkerPress}
              hideMarkers={hideMarkers}
              hideDistanceMarkers={hideDistanceMarkers}
              hideResetFilter={hideResetFilter}
            />
          )}
        {mapRef ? (
          <PolygonAndMarkerPlacement
            polygonDrawing={props.polygonDrawing}
            polygon={props.currentMarkedArea.polygon}
            onChange={onPolygonChange}
            center={props.currentMarkedArea.center}
            showSearch={showSearch}
            hideMarkers={hideMarkers}
            hideToolUi={hideToolUi}
            hideDistanceMarkers={hideDistanceMarkers}
            map={mapRef}
          />
        ) : null}
        {mapRef && !hideControls ? (
          <MapControlOverlay
            displayFilter={displayFilter}
            hideSearch={hideSearch}
            hideMarkers={hideMarkers}
            displayMarkers={displayMarkers}
            map={mapRef}
            markers={markers}
            displayGeoSearch={displayGeoSearch}
          />
        ) : null}
        {mapRef && props.markedAreaPolygons ? (
          <div key="69">
            {props.markedAreaPolygons(mapRef)}
          </div>
        ) : null}
      </GoogleMap>
    </div>
  );
};

export default MarkMapArea;

const filterPin = (markers, pinPosition) => {
  if (pinPosition) {
    return markers.filter(m => {
      return m.id !== pinPosition.key;
    });
  }

  return markers;
};

const getCenterAndZoom = (props, fitBounds) => {
  let center = props.position;
  let zoom = props.zoom !== null ? props.zoom : 12;
  if (!isValidPosition(center)) {
    center = get(props.pinPosition, "position");
    zoom = isValidPosition(center) ? 18 : 12;
  }
  if (fitBounds && props.fitBounds && props.markers.length) {
    // this will only set the center; fitBounds is called after map is rendered to set the proper zoom level as well
    if (props.fitBounds.getNorthEast) {
      const boundsCenter = props.fitBounds.getCenter();
      if (boundsCenter) {
        center = latLngToAppPos(boundsCenter);
      }
    } else {
      const boundsCenter = getBounds(
        props.markers.map(marker => marker.position),
      ).getCenter();
      if (boundsCenter) {
        center = latLngToAppPos(boundsCenter);
      }
    }
  }
  if (!isValidPosition(center)) {
    center = props.userPosition;
  }
  if (!isValidPosition(center)) {
    center = { latitude: 0, longitude: 0 };
    zoom = 1;
  }
  return { center: appPosToMapPos(center), zoom };
};
