import { useMemo } from "react";

const useNumberFormatter = (locale, precision = 0) => {
  const numberFormatter = useMemo(() => new Intl.NumberFormat(locale, {
    minimumFractionDigits: precision,
  }), [locale, precision]);
  return (value: number) => numberFormatter.format(value);
};

export default useNumberFormatter;
