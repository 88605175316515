import firebase from "../firebase";

export const remove = favorite => {
  return firebase
    .firestore()
    .collection("favorites")
    .doc(favorite.key)
    .delete()
    .catch(error => console.log("error deleting favorite", error));
};
