import React from "react";
import { get } from "lodash-es";
import { connect } from "react-redux";
import geokdbush from "geokdbush";

import { formatCommentDate, outputDate } from "farmerjoe-common";
import { canView, isAdmin, canDo } from "farmerjoe-common/lib/utils/User";
import { getColor } from "farmerjoe-common/lib/utils/Colors";
import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";
import {
  COMPANY_MAIN_GROUP_KEY,
  getBrowsingGroupKey,
} from "farmerjoe-common/lib/selectors/groups";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { NotACropState } from "farmerjoe-common/lib/flow/types";
import { hasCollaborators } from "farmerjoe-common/lib/utils/Field";
import type { Notification } from "farmerjoe-common/lib/flow/types";
import { getFieldNotifications } from "farmerjoe-common/lib/selectors/notifications";
import {
  DISPLAY_SETTINGS_SHOW_CROPS_WITH,
  DISPLAY_SETTINGS_SHOW_CROPS_WITH_AREA,
  DISPLAY_SETTINGS_SHOW_CROPS_WITH_PIECES,
} from "farmerjoe-common/lib/constants/filters";

import Mark from "../FieldMark/Mark";
import Waittime from "../WaitTime/Waittime";
import CropAge from "../Common/CropAge";
import Icon from "../Common/Icon";
import CropIcon from "../Common/CropIcon";
import { FieldName } from "../Common/FieldName";
import Fertilizing from "../Common/Fertilizing/Fertilizing";
import * as commentUtils from "../../utils/Comment";
import * as constants from "../../styles/style";
import type { Crop, Employee, Field, LatLng } from "../../flowTypes";
import I18n from "../../language/i18n";
import withRouter from "../Router/withRouter";
import useNumberFormatter from "../../hooks/useNumberFormatter";

import "./style.css";

type Props = {
  field: Field;
  crop: Crop;
  waitTimes: [];
  myCompanyProfile: Employee;
  showDistance?: boolean;
  simpleCropDisplay?: boolean;
  locationPermission: boolean;
  userPosition: LatLng;
  browsingAsGroup: string;
  notifications?: Array<Notification>;
  displaySettings?: any;
  showProducerNames?: boolean;
  showSharedByCompany?: boolean;
  openCompany?: string;
  history?: any;
  showCropPieces?: boolean;
  numberFormatter: (locale: string, precision: number) => (value: number) => string;
};

const styles = constants.styles;
const titleStyle = {
  ...styles.strong,
  ...styles.small,
  flex: 1,
  whiteSpace: "pre-line" as "pre-line",
  maxWidth: "220px",
};

class SingleField extends React.PureComponent<Props> {
  static defaultProps = {
    showDistance: false,
    simpleCropDisplay: true,
  };

  render() {
    const {
      field,
      crop,
      showDistance,
    } = this.props;

    const distance = showDistance ? this.renderDistance() : null;
    const fieldIsShared = hasCollaborators(field);

    return (
      <div className="single-field">
        <div
          className={
            "d-flex flex-row justify-content-center align-items-center"
          }
        >
          <FieldName
            fieldName={field.name}
            isSharedField={fieldIsShared}
          />
          { this.renderAreaOrQuantity(field, this.props.showCropPieces) }
          <CropAge
            style={{
              ...styles.small,
              ...styles.alignRight,
              ...{
                display: "inline-block",
                width: 50,
                fontWeight: "bold",
                color: constants.FJMUTEDDARK,
              },
            }}
            crop={crop}
          />

          {distance}
        </div>
        <div className={"d-flex flex-row justify-content-between"}>
          {this.renderCropInfo(crop)}
        </div>

        <div className={"d-flex flex-row "}>
          {this.renderAnalyses()}
          {this.renderPlantProtection()}
        </div>

        {this.renderProducer()}
        {this.renderSharedBy()}
        {this.renderCropDates(crop)}
        {this.renderYield(crop)}
        {this.renderMark()}
        {this.renderFertilizationResult()}
        {this.renderLastComment()}
        {this.renderNotifications()}
      </div>
    );
  }

  renderAreaOrQuantity(field, showCropPieces) {
    return (
      <>
        {showCropPieces
          ? this.renderCropPieces(field, this.props.numberFormatter)
          : this.renderArea(field)}
      </>
    );
  }

  renderArea(field) {
    return (
      <>
        <span
          className={"fj-muted fj-small"}
          style={{
            ...styles.alignRight,
            ...{
              width: 100,
              paddingRight: 2,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            },
          }}
        >
          {field.size ? field.size.toFixed(2) : 0}
        </span>
        <span className={"fj-muted fj-small"} style={{ width: 25 }}>
          ha
        </span>
      </>
    );
  };

  renderCropPieces(field, numberFormatter) {
    const pieces = get(field, "activeCrop.pieces", 0);
    return (
      <span
        className={"fj-muted fj-small"}
        style={{
          ...styles.alignRight,
          ...{
            width: 100,
            paddingRight: 2,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
        }}
      >
        {numberFormatter(pieces)} {I18n.t("crop.pieces.short")}
      </span>
    );
  };

  renderCropDates(crop) {
    if (crop.not_a_crop === NotACropState.NotACrop || !crop.harvested_on) {
      return null;
    }

    return (
      <div className={"d-flex justify-content-between"}>
        <span className={"fj-muted fj-small"}>
          {outputDate(crop.sown_on)} - {outputDate(crop.harvested_on)}
        </span>
      </div>
    );
  }

  renderYield(crop: Crop) {
    const total = get(crop, "yield.total", null);
    const perHa = get(crop, "yield.perHa", null);

    if (!crop.harvested_on || (!total && !perHa)) {
      return null;
    }

    return (
      <div className={"d-flex"}>
        <span className="fj-small fj-tag green">
          {I18n.t("crop.yield")}: {total} t {perHa} t/ha
        </span>
      </div>
    );
  }

  renderCropInfo(crop: Crop) {
    const { simpleCropDisplay } = this.props;
    const userCanNotSeeCropVariety = !isAdmin(this.props.myCompanyProfile) && !canDo("get.variety", "crop", this.props.myCompanyProfile);

    if (userCanNotSeeCropVariety) {
      return null;
    }

    if (simpleCropDisplay) {
      return (
        <span className={"fj-muted fj-small"} style={{ ...{ flex: 1 } }}>
          {crop.not_a_crop !== NotACropState.NotACrop ? crop.art : null}
        </span>
      );
    }

    return (
      <div
        className={"d-flex flex-row align-items-center"}
        style={{
          backgroundColor: getColor(crop.color),
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 3,
          paddingBottom: 3,
          borderRadius: 5,
          maxWidth: 200,
        }}
      >
        {crop.not_a_crop === NotACropState.NotACrop ? null : (
          <CropIcon crop={crop} defaultIconStyle={{ fontSize: 11 }} />
        )}
        <span
          /**/ style={{
            paddingRight: 5,
            marginRight: 5,
          }}
        >
          {crop.name}{" "}
          {crop.not_a_crop !== NotACropState.NotACrop && crop.art
            ? ` - ${crop.art}`
            : null}
        </span>
      </div>
    );
  }

  renderDistance() {
    const { locationPermission, userPosition, field } = this.props;

    if (
      locationPermission === null ||
      locationPermission === false ||
      userPosition === null
    ) {
      return null;
    }
    const distance = geokdbush.distance(
      userPosition.longitude,
      userPosition.latitude,
      field.position.longitude,
      field.position.latitude,
    );
    const tooFarAway = distance > 999;

    return (
      <div
        style={{
          backgroundColor: constants.FJAPPLEGREEN,
          marginLeft: 10,
          paddingTop: 3,
          paddingBottom: 3,
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 5,
        }}
        /* testID={"km"} */
      >
        {tooFarAway ? (
          <span style={{ color: "#FFF" }}>999+ km</span>
        ) : (
          <span style={{ color: "#FFF" }}>
            {distance.toFixed(distance < 10 ? 1 : 0)} km
          </span>
        )}
      </div>
    );
  }

  renderAnalyses() {
    const { analysesCount } = this.props.crop;
    const { myCompanyProfile } = this.props;
    const canView = isAdmin(myCompanyProfile);

    if (analysesCount && canView) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginRight: 10,
            alignItems: "center",
          }}
        >
          <span
            className={"fj-small"}
            style={{ paddingTop: 0, color: constants.FJMUTEDDARK }}
          >
            {analysesCount}
          </span>
          <Icon
            name="analysis"
            iconType={"fj"}
            style={{
              fontSize: 12,
              marginLeft: 5,
              color: constants.FJMUTEDDARK,
            }}
          />
        </div>
      );
    }
    return null;
  }

  renderProducer() {
    if (!this.props.showProducerNames) {
      return null;
    }

    const groupName =
      this.props.browsingAsGroup === COMPANY_MAIN_GROUP_KEY
        ? get(this.props.field, "groupMeta.name", null)
        : null;

    const derivedGroupName =
      groupName &&
      this.props.browsingAsGroup === COMPANY_MAIN_GROUP_KEY &&
      groupName !== COMPANY_MAIN_GROUP_KEY
        ? groupName
        : null;

    if (!derivedGroupName) {
      return null;
    }


    return (
      <div style={titleStyle} key="groupName">
        <Icon
          name={"tractor"}
          iconType={"fal"}
          style={{ fontSize: 12, marginRight: 5 }}
        />
        <span style={titleStyle} key={0}>
          {derivedGroupName}
        </span>
      </div>
    );
  }

  renderSharedBy() {
    if (!this.props.showSharedByCompany) {
      return null;
    }

    const name = get(this.props.field, "shared_by.company.name", null);
    const key = get(this.props.field, "shared_by.company.key", null);
    
    if (!name || !this.props.showSharedByCompany) {
      return null;
    }

    return (
      <div
        style={titleStyle}
        key="sharedBy"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          (this.props.history as any).replace(
            `/company/${this.props.openCompany}/field/${this.props.field.key}/collaborators/${key}`,
          );
        }}
      >
        <Icon
          name={"barn"}
          iconType={"fj"}
          style={{ fontSize: 12, marginRight: 5 }}
        />
        <span style={titleStyle}>{name}</span>
      </div>
    );
  }

  // Plant protection === waittime
  renderPlantProtection() {
    const waitTime =
      this.props.waitTimes && this.props.waitTimes.length ? (
        <Waittime
          iconStyle={{ fontSize: 12 }}
          style={styles.small}
          waitTimes={this.props.waitTimes}
        />
      ) : null;
    if (!waitTime) {
      return null;
    }
    return waitTime;
  }

  renderMark() {
    const mark = get(this.props.crop, "mark");
    return (
      <div className={"single-field-mark"}>
        <Mark mark={mark} />
      </div>
    );
  }

  renderFertilizationResult() {
    const cropType = get(this.props.crop, "harvested_on") ? "harvested" : "active";
    const showFertilizationResult = get(this.props.displaySettings, `${cropType}.showFertilizationResult`, false);
    const fertilizingTotal = get(this.props.crop, "fertilizingTotal");

    if (showFertilizationResult && fertilizingTotal) {
      return (
        <div className={"d-flex justify-content-center"}>
          <div className="fertilizing-comment">
            <Fertilizing
              sum={get(this.props.crop, "fertilizingTotal")}
              displayFertilizing={false}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  renderLastComment() {
    if (!canView("comments", this.props.myCompanyProfile)) {
      return null;
    }

    const lastComment = this.props.crop.lastComment;
    return (
      <div className={"d-flex flex-row justify-content-between gray-text"}>
        {lastComment
          ? [
            <div key={"last-comment-text"} className={"fj-small"}>
              {commentUtils.getCommentText(lastComment, 40)}
            </div>,
            <div key={"last-comment-created"} className={"fj-small"}>
              {formatCommentDate(lastComment.created)}
            </div>,
          ]
          : null}
      </div>
    );
  }
  
  renderNotifications() {
    const notifications = this.props.notifications;

    if (notifications && notifications.length) {
      return (
        <div className="notification-badge-container">
          <Icon
            name={"circle"}
            iconType={"fa"}
            className="notification-badge"
          />
        </div>
      );
    }
    return null;
  }
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const browsingAsGroup = getBrowsingGroupKey(state, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const notifications = getFieldNotifications(
    state,
    state.firebase.profile.uid,
    ownProps.field.company_id,
    ownProps.field.key,
  );
  const displaySettings = get(
    state,
    ["filtersByCompany", openCompany, "displaySettings"],
    {},
  );


  const currentFieldsTab = get(state, `currentFieldsTab[${openCompany}]`, null);
  const showProducerNames = get(
    displaySettings,
    `${currentFieldsTab}.showProducerNames`,
    false,
  );

  const showSharedByCompany = get(
    displaySettings,
    `${currentFieldsTab}.showSharedByCompany`,
    false,
  );

  const showCropPieces = get(
    displaySettings,
    [
      currentFieldsTab,
      DISPLAY_SETTINGS_SHOW_CROPS_WITH,
    ],
    DISPLAY_SETTINGS_SHOW_CROPS_WITH_AREA,
  ) === DISPLAY_SETTINGS_SHOW_CROPS_WITH_PIECES;

  return {
    browsingAsGroup,
    displaySettings,
    locationPermission: state.locationPermission,
    myCompanyProfile,
    notifications,
    openCompany,
    showProducerNames,
    showSharedByCompany,
    userPosition: state.userPosition,
    showCropPieces,
  };
};

const SingleFieldWithHook = (props) => {
  const numberFormatter = useNumberFormatter(I18n.locale, 0);
  return <SingleField {...props} numberFormatter={numberFormatter} />;
};


export default connect(selector)(withRouter(SingleFieldWithHook));
