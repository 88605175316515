import React, { useState, useRef } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import useSWR from "swr";

import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import { openGroup } from "farmerjoe-common/lib/actions/group";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getCompany } from "farmerjoe-common/lib/selectors/companies";
import {
  COMPANY_MAIN_GROUP_KEY,
  getCompanyGroups,
  getOpenGroupId,
  getGroupSearchWord,
  getBrowsingGroupKey,
} from "farmerjoe-common/lib/selectors/groups";
import { filters } from "farmerjoe-common/lib/actions/actions";
import { isAdmin } from "farmerjoe-common/lib/utils/User";
import {
  getCompanyGroupProfileForLoggedInUser,
} from "farmerjoe-common/lib/selectors/user";
import { getGroupsQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Groups";
import { getCompanyQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Companies";

import Table, { GroupsTableColumnDefinition } from "./Table";
import { getExportFileName, getNoGroupItemsText, GROUP_TYPE_PRODUCER, getMapMarkers, getAverageGeoLocation } from "../utils";
import NavbarBasic from "../../Common/NavbarBasic";
import { CreateNewButton } from "../../Common/NewButton";
import SearchInput from "../../Common/SearchInput";
import Icon from "../../Common/Icon";
import NoResults from "../../Common/NoResults";
import { Loading } from "../../Loading/Loading";
import { AlertDialog } from "../../Dialog/Dialog";
import Map from "../../Map/Map";

import I18n from "../../../language/i18n";
import * as constants from "../../../styles/style";
import CreateGroup from "../../../containers/Groups/Form";
import type { Company, Group, Employee } from "../../../flowTypes";
import firebase from "../../../data/firebase";
import { sendGetProducersStatisticsRequest } from "../../../data/queries/producers";
import { captureException } from "../../../utils/sentry";

/**
 * Typings for Props parsed from redux store
 */
type StateProps = {
  loading: boolean;
  openGroupId: string;
  email: string;
  myCompanyProfile: Employee;
  openCompany: string;
  company: Company;
  producers: Group[];

  /**
   * The search string entered in the search box
   */
  search: string;
};

/**
 * Typings for reducers from the redux store
 */
type DispatchProps = {
  actions: {
    openGroup: typeof openGroup;
    filters: typeof filters;
  };
};

type OwnProps = {
  history?: any[];
};

type Props = StateProps & DispatchProps & OwnProps;

enum StatisticsFields {
  activeFieldsArea = "activeFieldsArea",
  activeFieldsCount = "activeFieldsCount",
  harvestedFieldsArea = "harvestedFieldsArea",
  harvestedFieldsCount = "harvestedFieldsCount",
  plannedFieldsArea = "plannedFieldsArea",
  plannedFieldsCount = "plannedFieldsCount",
};

const ProducersTable = (props: Props) => {
  const tableRef = useRef<Table>(null);
  const summaryTable = useRef<Table>(null);

  const [isCreateFormVisible, showCreateForm] = useState(false);
  const [isAlertDialogVisible, setAlertDialogVisibility] = useState(false);
  const [alertDialogTitle, setAlertDialogTitle] = useState("");
  const [alertDialogBody, setAlertDialogBody] = useState("");
  const [toggleMap, setToggleMap] = useState(false);

  const { producers, company, myCompanyProfile, loading, search, actions, history, openGroupId } = props;

  const { statistics, isLoadingStatistics } = useSwrToFetchProducersStatistics(company.key);

  const onClick = async () => {
    showCreateForm(true);
  };

  const renderCreateButtonIfAllowed = () => {
    if (!canEdit(myCompanyProfile)) return null;
    return <CreateNewButton onClick={onClick} />;
  };

  const renderCreateFormIfNeeded = () => {
    if (!isCreateFormVisible) return null;
    return <CreateGroup
      type="producer"
      show={true}
      onClose={() => showCreateForm(false)}
    />;
  };

  const onRowClick = (rowId: string) => {
    const group = producers.find(group => group.key === rowId);
    if (!group) return;
    actions.openGroup(group.key);
    history?.push(
      `/company/${company.key}/producer/${group.key}`,
    );
  };

  const showAlert = (title: string, message: string) => {
    setAlertDialogTitle(title);
    setAlertDialogBody(message);
    setAlertDialogVisibility(true);
  };

  const runSearch = text => actions.filters(company.key, { producerSearch: text } as any);

  if (loading || isLoadingStatistics) {
    return <Loading />;
  }

  const producersWithStatistics = mergeStatisticsAndProducers(statistics, producers);
  const columns = getProducersTableColumnsDefinition();
  const tableRowData = mapProducersToTableRows(producersWithStatistics);

  const summary = tableRowData.reduce((curr, val) => {
    curr.countProducers = curr.countProducers + 1;
    curr.plannedFieldsArea += Number(val.plannedFieldsArea || 0);
    curr.plannedFieldsCount += Number(val.plannedFieldsCount || 0);
    curr.activeFieldsArea += Number(val.activeFieldsArea || 0);
    curr.activeFieldsCount += Number(val.activeFieldsCount || 0);
    curr.harvestedFieldsArea += Number(val.harvestedFieldsArea || 0);
    curr.harvestedFieldsCount += Number(val.harvestedFieldsCount || 0);
    return curr;
  }, { countProducers: 0, plannedFieldsArea: 0, plannedFieldsCount: 0, activeFieldsArea: 0, activeFieldsCount: 0, harvestedFieldsArea: 0, harvestedFieldsCount: 0 });

  const summaryTableColumns = [
    {
      name: "countProducers",
      headerLabel: I18n.t("producers.all"),
      style: { textAlign: "right" },
    },
    { name: "empty", headerLabel: "" },
    {
      name: "plannedFieldsArea",
      headerLabel: `${I18n.t("crop.planned")} (ha)`,
      style: { textAlign: "right" },
    },
    {
      name: "plannedFieldsCount",
      headerLabel: I18n.t("crop.planned"),
      style: { textAlign: "right" },
    },
    {
      name: "activeFieldsArea",
      headerLabel: `${I18n.t("crop.active")} (ha)`,
      style: { textAlign: "right" },
    },
    {
      name: "activeFieldsCount",
      headerLabel: I18n.t("crop.active"),
      style: { textAlign: "right" },
    },
    {
      name: "harvestedFieldsArea",
      headerLabel: `${I18n.t("harvested")} (ha)`,
      style: { textAlign: "right" },
    },
    {
      name: "harvestedFieldsCount",
      headerLabel: I18n.t("harvested"),
      style: { textAlign: "right" },
    },
  ];
  const summaryTableRowData = [
    {
      countProducers: summary.countProducers,
      empty: null,
      plannedFieldsArea: summary.plannedFieldsArea.toFixed(1),
      plannedFieldsCount: summary.plannedFieldsCount,
      activeFieldsArea: summary.activeFieldsArea.toFixed(1),
      activeFieldsCount: summary.activeFieldsCount,
      harvestedFieldsArea: summary.harvestedFieldsArea.toFixed(1),
      harvestedFieldsCount: summary.harvestedFieldsCount,
    },
  ];

  const markers = getMapMarkers(producers, GROUP_TYPE_PRODUCER, (producerKey: string) => {
    actions.openGroup(producerKey);
    history?.push(
      `/company/${props.openCompany}/producer/${producerKey}`,
    );
  });
  const position = getAverageGeoLocation(producers);

  return (
    <div className="producers-table">
      <div style={constants.styles.containerColumn}>
        <NavbarBasic
          title={I18n.t("producers.plural")}
          rightButton={renderCreateButtonIfAllowed()}
        />

        <div className="toolbar-container">
          <SearchInput
            onChange={runSearch}
            search={search}
          />
          <button
            className="btn btn-secondary column-config-button"
            onClick={() => tableRef.current?.exportData()}>
            <Icon iconType="fa" name="file-csv" style={{ fontSize: 20 }} />{" "}
            {I18n.t("export")}
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setToggleMap(!toggleMap)}>
            <Icon iconType="fa" name="map" style={{ fontSize: 20 }} />{" "}
            {I18n.t("map")}
          </button>
        </div>

        {toggleMap ? (
          <div style={{ height: "50%" }}>
            <Map
              mapStyles={{}}
              filterNotACrop={false}
              position={position}
              onClick={() => { }}
              markers={markers}
            />
          </div>
        ) : null}

        <div className={"scrollable-tab active"} style={{ flex: "0 1 100px" }}>
          <Table
            ref={summaryTable}
            loading={false}
            emptyView={null as any}
            onRowClick={() => { }}
            exportFileName={""}
            columns={summaryTableColumns}
            tableRowData={summaryTableRowData}
            selectedRowId={null as any}
          />
        </div>


        <div className={"scrollable-tab active"}>
          <Table
            ref={tableRef}
            loading={false}
            emptyView={(<NoResults text={getNoGroupItemsText(search, "producer")} />)}
            onRowClick={onRowClick}
            exportFileName={getExportFileName("producer")}
            columns={columns}
            tableRowData={tableRowData}
            selectedRowId={openGroupId}
          />
        </div>

        {renderCreateFormIfNeeded()}
      </div>
      <AlertDialog
        show={isAlertDialogVisible}
        onClose={() => setAlertDialogVisibility(false)}
        title={alertDialogTitle}
      >
        {alertDialogBody}
      </AlertDialog>
    </div>
  );
};

const getProducersTableColumnsDefinition = (): GroupsTableColumnDefinition[] => {
  return [
    { name: "name", headerLabel: I18n.t("producers.name") },
    { name: "producerNumber", headerLabel: I18n.t("producers.number") },
    {
      name: StatisticsFields.plannedFieldsArea,
      headerLabel: `${I18n.t("crop.planned")} (ha)`,
      style: { textAlign: "right" },
    },
    {
      name: StatisticsFields.plannedFieldsCount,
      headerLabel: I18n.t("crop.planned"),
      style: { textAlign: "right" },
    },
    {
      name: StatisticsFields.activeFieldsArea,
      headerLabel: `${I18n.t("crop.active")} (ha)`,
      style: { textAlign: "right" },
    },
    {
      name: StatisticsFields.activeFieldsCount,
      headerLabel: I18n.t("crop.active"),
      style: { textAlign: "right" },
    },
    {
      name: StatisticsFields.harvestedFieldsArea,
      headerLabel: `${I18n.t("harvested")} (ha)`,
      style: { textAlign: "right" },
    },
    {
      name: StatisticsFields.harvestedFieldsCount,
      headerLabel: I18n.t("harvested"),
      style: { textAlign: "right" },
    },
    { name: "phoneNumber", headerLabel: I18n.t("mobile_phone") },
    { name: "email", headerLabel: I18n.t("email") },
    { name: "street", headerLabel: I18n.t("street") },
    { name: "city", headerLabel: I18n.t("city") },
    { name: "country", headerLabel: I18n.t("country") },
    { name: "zip", headerLabel: I18n.t("zip") },
    { name: "ggnNumber", headerLabel: I18n.t("ggn_gln_number") },
    { name: "qsNumber", headerLabel: I18n.t("company.qs_number") },
    { name: "contactPersonName", headerLabel: I18n.t("name") },
    { name: "contactPersonTel", headerLabel: I18n.t("mobile_phone") },
    { name: "contactPersonEmail", headerLabel: I18n.t("email") },
    { name: "notes", headerLabel: I18n.t("notes") },
  ];
};

const useSwrToFetchProducersStatistics = (companyId: string) => {
  const user = firebase.auth().currentUser;

  const { data, error } = useSWR(
    `/producers/fieldStatistics?companyId=${companyId}`,
    () => sendGetProducersStatisticsRequest(companyId, user));

  let statistics: any = [];
  if (error) {
    logError("Failed to fetch producer statistics.", error, { id: companyId });
  } else {
    statistics = data;
  }

  const isLoadingStatistics = !data;

  return { isLoadingStatistics, statistics };
};

const canEdit = (myCompanyProfile) => {
  return isAdmin(myCompanyProfile) &&
    myCompanyProfile.group_id === COMPANY_MAIN_GROUP_KEY;
};

const roundToTwoDigits = (input: number) => {
  const result = Math.round((input + Number.EPSILON) * 100) / 100;
  return result.toFixed(1);
};

const mapProducersToTableRows = (producers): Record<string, any>[] => {
  if (!Array.isArray(producers)) return [];

  return producers.map(group => ({
    id: group.key,
    name: group.name,
    phoneNumber: group.tel,
    email: group.email,
    street: group.street,
    city: group.city,
    country: group.country,
    zip: group.zip,
    ggnNumber: group.ggn_number,
    qsNumber: group.qs_number,
    producerNumber: group.group_number,
    contactPersonName: group.contact?.name,
    contactPersonEmail: group.contact?.email,
    contactPersonTel: group.contact?.tel,
    notes: group.notes,
    [StatisticsFields.activeFieldsArea]: group[StatisticsFields.activeFieldsArea],
    [StatisticsFields.activeFieldsCount]: group[StatisticsFields.activeFieldsCount],
    [StatisticsFields.harvestedFieldsArea]: group[StatisticsFields.harvestedFieldsArea],
    [StatisticsFields.harvestedFieldsCount]: group[StatisticsFields.harvestedFieldsCount],
    [StatisticsFields.plannedFieldsArea]: group[StatisticsFields.plannedFieldsArea],
    [StatisticsFields.plannedFieldsCount]: group[StatisticsFields.plannedFieldsCount],
  }));
};

const mergeStatisticsAndProducers = (statistics, producers) => {
  if (!Array.isArray(statistics)) {
    logError("Unexpected value instead of an array.", {}, { response: statistics });
    return producers;
  }
  if (!statistics) return producers;

  const statsMap = {};
  for (let idx = 0; idx < statistics.length; idx++) {
    statsMap[statistics[idx].id] = idx;
  }

  const producersWithStatistics: any[] = [];
  for (const group of producers) {
    const producer: any = {
      ...group,
    };
    const idx = statsMap[group.key];
    if (idx !== undefined) {
      const stats = statistics[idx];
      producer[StatisticsFields.activeFieldsArea] = roundToTwoDigits(stats.activeFields.area);
      producer[StatisticsFields.activeFieldsCount] = stats.activeFields.count;
      producer[StatisticsFields.harvestedFieldsArea] = roundToTwoDigits(stats.harvestedFields.area);
      producer[StatisticsFields.harvestedFieldsCount] = stats.harvestedFields.count;
      producer[StatisticsFields.plannedFieldsArea] = roundToTwoDigits(stats.plannedFields.area);
      producer[StatisticsFields.plannedFieldsCount] = stats.plannedFields.count;
    }
    producersWithStatistics.push(producer);
  }

  return producersWithStatistics;
};

const logError = (message: string, error, metadata?) => {
  captureException(error, metadata);
  console.error(message);
};

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          openGroup,
          filters,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state, ownProps): StateProps => {
  const openCompany = selectors.getOpenCompanyId(state);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const company = getCompany(state.firestore.data, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const search = getGroupSearchWord(state, openCompany);
  const type = ownProps.type;
  // XXX: Becouse the table is generated by the API, we need to filter the external producers
  // i.e. those coming from shared fields
  const producers = getCompanyGroups(state, openCompany, type, browsingGroup).filter(p => !p.external);
  const openGroupId = getOpenGroupId(state);
  const hasLoadedEverything = hasLoaded(
    [getGroupsQuery(openCompany, myCompanyProfile, type)],
    state,
  );

  return {
    loading: !hasLoadedEverything,
    openGroupId,
    email: state.firebase.profile.email,
    myCompanyProfile,
    openCompany,
    company,
    producers,
    search,
  };
};

const wrappedGroups = firestoreConnect(props => {
  const { email, openCompany, type, myCompanyProfile } = props;
  if (!email) return [];

  return [
    getCompanyQuery(openCompany),
    getGroupsQuery(openCompany, myCompanyProfile, type),
  ];
})(ProducersTable);

export default compose<any>(
  connect<StateProps, DispatchProps, OwnProps>(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(wrappedGroups);
