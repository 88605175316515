import React from "react";
import { DefaultTabBar } from "../../Common/ScrollableTabView";
import I18n from "../../../language/i18n";
import SearchInput from "../../Common/SearchInput";
import Icon from "../../Common/Icon";
import withRouter from "../../Router/withRouter";
import { compose } from "redux";
import { CreateNewButton } from "../../Common/NewButton";
import "./style.css";
import EmployeesViewSwitch from "../EmployeesViewSwitch";

type Props = {
  showNew: boolean;
  onShowCreateForm: () => void;
  search: string;
  company?: any;
  onSearch?: (arg0: string) => void;
  onColumnConfigClick?: any;
  tab?: any;
  history?: any;
  location?: any;
  onExportClick?: () => void;
};

class TabBar extends React.Component<Props> {
  render() {
    const {
      showNew,
      onShowCreateForm,
      search,
      company,
      onSearch,
      onColumnConfigClick,
      tab,
      history,
      location,
      onExportClick,
      ...restProps
    } = this.props;
    return (
      <React.Fragment>
        <div className="page-card-top-bar-container">
          <EmployeesViewSwitch key="employees-view-switch" />
          <DefaultTabBar {...restProps} />
          {showNew
            ? (
              <CreateNewButton onClick={onShowCreateForm} />
            )
            : (
              <div style={{ width: 35 }}></div>
            )}
        </div>
        <div className="toolbar-container">
          <SearchInput
            onChange={onSearch}
            search={search}
          />
          <button
            className="btn btn-secondary column-config-button"
            onClick={onExportClick}>
            <Icon iconType="fa" name="file-csv" style={{ fontSize: 20 }} />{" "}
            {I18n.t("export")}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default compose<typeof TabBar>(withRouter)(TabBar);
