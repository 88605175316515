import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash-es";

import { getUsage } from "farmerjoe-common/lib/requests/billing";
import { isAdmin } from "farmerjoe-common/lib/utils/User";
import { UserRole, UserState } from "farmerjoe-common/lib/flow/types";
import { SUBSCRIPTION_PLAN_QUOTA_REACHED } from "farmerjoe-common/lib/constants/billing";

import "./style.css";
import Employee from "./Employee";
import EmployeesViewSwitch from "./EmployeesViewSwitch";
import { canAddNewEmployees, convertUserObjects } from "./utils";

import NoResults from "../Common/NoResults";
import FlatList from "../Common/FlatList";
import ScrollableTabView from "../Common/ScrollableTabView";
import TabBarSearchSort from "../Common/TabBarSearchSort";
import SearchInput from "../Common/SearchInput";
import Icon from "../Common/Icon";
import { CreateNewButton } from "../Common/NewButton";
import GlobalUserSearch from "../GlobalUserSearch/GlobalUserSearch";
import { NotActivatedWarning } from "../Activation/NotActivatedWarning";
import { AlertDialog } from "../Dialog/Dialog";
import I18n from "../../language/i18n";
import { getUidToken } from "../../utils/auth";
import fuseSearch from "../../utils/search";
import { DataContext } from "../../contexts/CompanyEmployeesProvider";

// TODO: improve typings
type Props = any;
type State = any;

const Employees = (props) => {
  const {
    actions,
    auth,
    company,
    filter,
    myCompanyProfile,
    selectedEmployee,
    users,
  } = props;

  const [showCreateEmployeeDialog, setShowCreateEmployeeDialog] = useState(false);
  const [alertDialog, setAlertDialog] = useState<{isVisible: boolean, title: string, message: string | React.ReactNode}>({ isVisible: false, title: "", message: "" });
  const [role, setRole] = useState("");
  const [search, setSearch] = useState("");

  const { refreshData } = useContext(DataContext);

  const onClick = async () => {
    const uidToken = await getUidToken();
    return getUsage(uidToken, company.key)
      .then(async (response) => {
        if (response.status !== 200) {
          throw new Error("Unexpected error occured! Try again!");
        }
        const content = await response.json();
        if (content.totalUsers >= content.maxAllowedUsers) {
          throw new Error(SUBSCRIPTION_PLAN_QUOTA_REACHED);
        }
        setShowCreateEmployeeDialog(true);
      })
      .catch(error => {
        handleCheckUsageLimitsError(error);
      });
  };

  const handleCheckUsageLimitsError = (error: Error) => {
    if (error.message === SUBSCRIPTION_PLAN_QUOTA_REACHED) {
      const message = <div>
        <div> {I18n.t("billing.addMoreSeats")} </div>
        <Link
          key={"billing"}
          to={`/company/${props.company.key}/billing`}
          onClick={closeAlert.bind(this)}
        >
          {I18n.t("billing.editSeats")} &#8594;
        </Link>
      </div>;

      setShowCreateEmployeeDialog(false);
      showAlert(I18n.t("billing.addSeats"), message);
      return;
    }

    setShowCreateEmployeeDialog(false);
    showAlert(I18n.t("error"), error.message);
  };

  const showAlert = (title: string, message: string | React.ReactNode) => {
    setAlertDialog({ isVisible: true, title, message });
  };

  const closeAlert = () => {
    setAlertDialog({ isVisible: false, title: "", message: ""});
  };

  const allUsers = convertUserObjects(users, auth.uid);
  const filteredUsers = search ? fuseSearch(allUsers, search, { keys: ["name", "email"] }) : allUsers;
  const employees = filteredUsers.filter(
    user => user.state !== UserState.Deactivated && user.role !== UserRole.Advisor,
  );
  const advisors = filteredUsers.filter(
    user => user.state !== UserState.Deactivated && user.role === UserRole.Advisor,
  );
  const deactivated = filteredUsers.filter(user => user.state && user.state === UserState.Deactivated);

  const showNew = canAddNewEmployees(myCompanyProfile, auth);

  const onChange = debounce((word) => setSearch(word), 300);

  return (
    <div className="employees">
      {isAdmin(myCompanyProfile) && !auth.emailVerified && (
        <NotActivatedWarning type={"employee"} />
      )}
      <ScrollableTabView
        onChangeTab={({ name }) => {
          // if on the advisors tab set the role to advisor
          if (name === I18n.t("employees.advisors")) {
            setRole(UserRole.Advisor);
          }

          if (role !== "") {
            setRole("");
          }
        }}
        renderTabBar={() => (
          <TabBarSearchSort
            showSort={false}
            searchComponent={
              <SearchInput
                onChange={onChange}
                search={search}
              />
            }
            onCloseSearch={() => {
              setSearch("");
            }}
            sortComponent={
              <div
                style={{
                  backgroundColor: "yellow",
                }}>
                <Icon name={"ios-trash-outline"} key={"ios-trash-outline"} />
              </div>
            }
            beforeTabBarComponent={
              <EmployeesViewSwitch key="employees-view-switch" />
            }
            afterTabBarComponent={
              showNew
                ? (
                  <CreateNewButton onClick={() => {
                    onClick();
                  }}
                  />
                )
                : null
            }
          />
        )}>
        <FlatList
          className="employees-list"
          tabLabel={I18n.t("employees.plural")}
          ListEmptyComponent={
            <NoResults
              text={
                filter === "deactivated"
                  ? I18n.t("employees.noDeactivatedUsers")
                  : I18n.t("noSearchResults")
              }
            />
          }
          data={employees}
          renderItem={({ item }) => (
            <Employee
              user={item}
              company={company}
              actions={actions}
              myCompanyProfile={myCompanyProfile}
              selected={selectedEmployee === item.key}
            />
          )}
        />

        <FlatList
          labelKey={"advisors"}
          className="employees-list"
          tabLabel={`${I18n.t("employees.advisors")}`}
          ListEmptyComponent={
            <NoResults
              text={
                filter === "deactivated"
                  ? I18n.t("employees.noDeactivatedUsers")
                  : I18n.t("employees.noAdvisors")
              }
            />
          }
          data={advisors}
          renderItem={({ item }) => (
            <Employee
              user={item}
              company={company}
              actions={actions}
              myCompanyProfile={myCompanyProfile}
              selected={selectedEmployee === item.key}
            />
          )}
        />

        <FlatList
          className="employees-list"
          tabLabel={`${I18n.t("employees.deactivated")}`}
          ListEmptyComponent={
            <NoResults text={I18n.t("employees.noDeactivatedUsers")} />
          }
          data={deactivated}
          renderItem={({ item }) => (
            <Employee
              user={item}
              company={company}
              actions={actions}
              myCompanyProfile={myCompanyProfile}
              selected={selectedEmployee === item.key}
            />
          )}
        />
      </ScrollableTabView>

      {showCreateEmployeeDialog
        ? (
          <GlobalUserSearch
            show={showCreateEmployeeDialog}
            onClose={() => {
              setShowCreateEmployeeDialog(false);
              refreshData();
            }}
            groupType="employee"
          />
        )
        : null}

      <AlertDialog
        show={alertDialog.isVisible}
        onClose={closeAlert}
        title={alertDialog.title}
        key="alert"
      >
        {alertDialog.message}
      </AlertDialog>
    </div>
  );
};

export default Employees;
