type TDefinePrecropsParams = {
  fieldPrecrops: string[];
  precropsFromApi: string[];
  precropsFromForm: string[];
  precropsToDelete: string[];
};

export const definePrecrops = ({
  fieldPrecrops = [],
  precropsFromApi = [],
  precropsFromForm = [],
  precropsToDelete = [],
}: TDefinePrecropsParams) => {
  const allPrecrops: Set<string> = new Set();

  // Add user-defined precrops not in API crops
  if (fieldPrecrops.length) {
    const apiCrops = new Set(precropsFromApi || []);
    fieldPrecrops.forEach(p => {
      if (!apiCrops.has(p)) allPrecrops.add(p);
    });
  }

  // Add extra precrops from the form
  if (precropsFromForm.length) {
    precropsFromForm.forEach(p => allPrecrops.add(p));
  }

  // Add precrops from the API
  if (precropsFromApi) {
    precropsFromApi.forEach(p => allPrecrops.add(p));
  }

  // Filter out deleted precrops
  const _precropsToDelete = new Set(precropsToDelete);
  const allPrecropsWithoutDeleted = Array.from(allPrecrops).filter(p => !_precropsToDelete.has(p));

  return allPrecropsWithoutDeleted;
};
