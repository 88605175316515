import React from "react";
import { sort } from "ramda";
import { components, createFilter } from "react-select";
import { find, get } from "lodash-es";
import moize from "moize";

import CountryItem from "./CountryItem";
import Select from "../Select";
import I18n from "../../../language/i18n";
import Icon from "../../../components/Common/Icon";
import { styles, FJBROWN } from "../../../styles/style";
import { getCountries } from "../../../utils/Countries";
import { collatorSort } from "../../../utils/String";
import "./style.css";

const getCountryName = moize(cca2 => {
  const country = find(getCountries(), { cca2 });
  return country ? country.name : cca2;
});

const customFilter = createFilter({
  stringify: ({ data: { name, cca2 } }) => `${name} ${cca2}`,
});

type Props = {
  /**
   * the cca2 code for the country
   */
  cca2: string;

  /**
   * the country code
   */
  onChange: (arg0: Record<string, any>) => void;
  onBlur?: React.FocusEventHandler;

  editable: boolean;
  hasError: boolean;
  style?: Record<string, any>;
  countries: Array<Record<string, any>>;
  ListItem: React.ComponentType<any>;
  renderValue?: (arg0: string) => React.ReactElement<any> | null;
  filterOption?: (arg0: Record<string, any>) => (...args: Array<any>) => any;
  ValueItem: React.ComponentType<any>;
};

type State = {
  filter: null | string;
  data: any;
};

class CountryListBase extends React.PureComponent<Props, State> {
  static defaultProps = {
    ListItem: CountryItem,
    filterOption: customFilter,
    ValueItem: CountryItem,
  };

  initialData = [
    {
      data: sort(
        collatorSort,
        this.props.countries.filter(country => {
          return ["DE", "US", "RU", "FR", "IT", "GB", "AT", "CH"].includes(
            country.cca2,
          );
        }),
      ),
      title: "",
    },
    { data: this.props.countries, title: I18n.t("countries.all") },
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      filter: null,
      data: this.initialData,
    };
  }

  render() {
    const {
      editable,
      hasError,
      cca2,
      style,
      filterOption,
      ListItem,
      renderValue,
      ValueItem,
    } = this.props;
    const { data } = this.state;
    const editableValue = typeof editable !== "undefined" ? editable : true;
    let errorStyle = {};
    if (hasError) {
      errorStyle = styles.danger;
    }

    const selectOptions = data.map(({ data, title }) => ({
      options: data,
      label: title,
    }));

    const selectedCountry = renderValue
      ? (
        renderValue(cca2)
      )
      : (
        <div
          style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <span
            style={{
              ...errorStyle,
              ...(editable ? null : { color: "grey" }),
              ...{ color: "grey" },
            }}>
            {cca2 ? getCountryName(cca2) : null}
          </span>
          <Icon
            name={"arrow_down"}
            iconType={"fj"}
            style={{
              ...{
                fontSize: 8,
                marginTop: 5,
                marginLeft: 5,
                marginRight: 10,
                color: FJBROWN,
              },
              ...errorStyle,
            }}
          />
        </div>
      );

    return (
      <div style={style}>
        {editableValue
          ? (
            <div>
              <Select
                className={"country-tel-select"}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                getOptionValue={item => item.cca2}
                options={selectOptions}
                filterOption={filterOption}
                components={{
                  Option: props => (
                    <components.Option {...props}>
                      <ListItem item={props.data} />
                    </components.Option>
                  ),
                  SingleValue: ({ children, ...props }) => (
                    <components.SingleValue {...props}>
                      <ValueItem item={get(props.getValue(), "0")} />
                    </components.SingleValue>
                  ),
                }}
                value={find(selectOptions[1].options, { cca2 })}
                isSearchable
                styles={{
                  control: (base, state) => ({
                    ...base,
                    ...(hasError
                      ? { borderColor: styles.danger.color || "red" }
                      : null),
                  }),
                }}
              />{" "}
            </div>
          )
          : (
            <div>{selectedCountry}</div>
          )}
      </div>
    );
  }
}

export default React.forwardRef<unknown, any>(function CountryList(
  { countries, ...props },
  ref,
) {
  return (
    <CountryListBase
      ref={ref}
      {...props}
      countries={countries || getCountries()}
    />
  );
});
