import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get } from "lodash-es";

import * as employeeCreators from "farmerjoe-common/lib/actions/employee";
import type {
  Company,
  Field,
  Employee as EmployeeType,
} from "farmerjoe-common/lib/flow/types";
import { UserRole } from "farmerjoe-common/lib/flow/types";
import {
  getCompanyGroupProfileForLoggedInUser,
} from "farmerjoe-common/lib/selectors/user";
import { isAdmin } from "farmerjoe-common/lib/utils/User";
import { getOpenCompanyId} from "farmerjoe-common/lib/selectors/selectors";

import Icon from "../Common/Icon";
import Text from "../Common/Text";
import { AlertConfirmDialog } from "../Dialog/Dialog";
import * as constants from "../../styles/style";
import { Avatar } from "../Common/Avatar";
import Role from "./Role";
import I18n from "../../language/i18n";
// import { editEmployee, updateEmployee } from 'farmerjoe-common'
import CreateEmployee from "../../containers/Employees/CreateEmployee";

const DIALOG_EDIT_RESULT = "edit";
const DIALOG_CONFIRM_RESULT = "yes";

type Props = {
  user: EmployeeType;
  hasAccess: boolean;
  company: Company;
  field: Field;
  actions: {
    editEmployee: any; // typeof editEmployee
    updateEmployee: any; // typeof updateEmployee
  };
  myCompanyProfile: EmployeeType;
};

type State = {
  confirm: Record<string, any> | null;
  showCreateEmployeeDialog: boolean;
  editEmployeeGroupId: string | null;
};

class Employee extends Component<Props, State> {
  state = {
    confirm: null,
    showCreateEmployeeDialog: false,
    editEmployeeGroupId: null,
  };

  render() {
    const { user, hasAccess, company, field, myCompanyProfile } = this.props;
    let invited: any = null;

    if (user.active !== true && !user.accepted_on) {
      invited = true;
    }

    const standardUserWithAccessToAllFields =
      [UserRole.Standard, UserRole.Advisor].includes(user.role as UserRole) &&
      get(user, "views.fields", false);
    const hasUnrestrictedAccess =
      user.role === UserRole.Admin || standardUserWithAccessToAllFields;

    const color = hasAccess ? constants.FJMUTEDLIGHT : "#000";

    const avatarURL = get(user, "profile.avatar.original.downloadURL", null);

    return (
      <div
        key={user.key}
        style={{ margin: 10, paddingLeft: 10, paddingRight: 10 }}>
        <div
          style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
          onClick={() => {
            if (!isAdmin(myCompanyProfile)) {
              return;
            }
            if (user.role === UserRole.Admin) {
              this.setState({
                confirm: {
                  title: I18n.t("employees.admin"),
                  children: I18n.t("acl.access.fieldAdminAlert.message"),
                  buttons: [
                    {
                      label: I18n.t("cancel"),
                      value: "cancel",
                      className: "btn-secondary",
                    },
                    {
                      label: I18n.t("edit"),
                      value: DIALOG_EDIT_RESULT,
                      className: "btn-primary",
                    },
                  ],
                  onClose: result => {
                    if (result === DIALOG_EDIT_RESULT) {
                      this.props.actions.editEmployee(company.key, user.key);
                      this.setState({
                        showCreateEmployeeDialog: true,
                        editEmployeeGroupId: user.group_id,
                      });
                    }
                    this.setState({ confirm: null });
                  },
                },
              });
              return;
            }

            if (hasAccess) {
              this.setState({
                confirm: {
                  title: user.name,
                  children: I18n.t("acl.access.forbidFieldAlert.message", {
                    name: user.name,
                  }),
                  buttons: [
                    {
                      label: I18n.t("no"),
                      value: "no",
                      className: "btn-secondary",
                    },
                    {
                      label: I18n.t("yes"),
                      value: DIALOG_CONFIRM_RESULT,
                      className: "btn-primary",
                    },
                  ],
                  onClose: result => {
                    if (result === DIALOG_CONFIRM_RESULT) {
                      const fields = user.fields ? { ...user.fields } : {};

                      if (fields) {
                        delete fields[field.key];
                      }

                      this.props.actions.updateEmployee(company, {
                        ...user,
                        fields: fields,
                      });
                    }
                    this.setState({ confirm: null });
                  },
                },
              });
            } else {
              this.setState({
                confirm: {
                  title: user.name,
                  children: I18n.t("acl.access.grantFieldAlert.message", {
                    name: user.name,
                  }),
                  buttons: [
                    {
                      label: I18n.t("no"),
                      value: "no",
                      className: "btn-secondary",
                    },
                    {
                      label: I18n.t("yes"),
                      value: DIALOG_CONFIRM_RESULT,
                      className: "btn-primary",
                    },
                  ],
                  onClose: result => {
                    if (result === DIALOG_CONFIRM_RESULT) {
                      this.props.actions.updateEmployee(company, {
                        ...user,
                        fields: {
                          ...user.fields,
                          [field.key]: true,
                        },
                      });
                    }
                    this.setState({ confirm: null });
                  },
                },
              });
            }
          }}>
          <div style={{ flex: 1 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginRight: 10 }}>
                <div style={{ position: "relative" }}>
                  <Avatar
                    downloadUrl={avatarURL}
                    isLoaded={true}
                    style={{ backgroundColor: "#EEE" }}
                    round={true}
                    size={"small"}
                  />
                </div>
              </div>
              <div
                style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    position: "relative",
                  }}>
                  <Text style={{ ...{ fontWeight: "bold", color: color } }}>
                    {user.name}
                  </Text>
                  {invited
                    ? (
                      <Icon
                        name={"ios-mail-outline"}
                        style={{
                          fontSize: 25,
                          marginLeft: 5,
                          height: 28,
                          lineHeight: "22px",
                        }}
                      />
                    )
                    : null}
                </div>
                <Role user={user as any} />
                {hasAccess
                  ? (
                    <Text
                      style={{
                        ...constants.styles.small,
                        ...constants.styles.muted,
                        ...constants.styles.italic,
                      }}>
                      {user.invitation
                        ? I18n.t("acl.access.accessOnceInvitationAccepted")
                        : I18n.t("acl.access.alreadyHasAccess")}
                    </Text>
                  )
                  : null}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <Icon
                  name={
                    hasAccess ? "ios-checkmark-circle" : "ios-radio-button-off"
                  }
                  style={{
                    fontSize: 30,
                    color: hasUnrestrictedAccess
                      ? constants.FJMUTEDLIGHT
                      : constants.FJNEWGREEN,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <AlertConfirmDialog
          {...this.state.confirm}
          show={!!this.state.confirm}
          key={4}
        />
        {this.state.showCreateEmployeeDialog
          ? (
            <CreateEmployee
              show={this.state.showCreateEmployeeDialog}
              groupId={this.state.editEmployeeGroupId}
              onClose={() =>
                this.setState({
                  showCreateEmployeeDialog: false,
                  editEmployeeGroupId: null,
                })
              }
            />
          )
          : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...employeeCreators,
        },
      ),
      dispatch,
    ),
  };
};

export default connect(
  (state: any, ownProps: any) => {
    const auth = state.firebase.auth;
    const openCompanyId = getOpenCompanyId(state);
    const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(state, openCompanyId);
    return {
      user: ownProps.user,
      auth,
      myCompanyProfile,
    };
  },
  mapDispatchToProps,
)(Employee);
