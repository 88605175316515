import I18n from "i18n-js";
import firebase from "../data/firebase";
import {
  getLocaleData,
  isLocaleLoaded,
  isLocaleSupported,
  loadLocale,
} from "@farmerjoeorg/farmerjoe-language";
import farmerJoeEn from "@farmerjoeorg/farmerjoe-language/dist/languages/en/farmerJoe";
import countriesEn from "@farmerjoeorg/farmerjoe-language/dist/languages/en/countries";
import moment from "moment";
import "@farmerjoeorg/farmerjoe-language/dist/languages/defaultVendors";

I18n.defaultLocale = "en";
I18n.locale = browserLanguage();

const translations = {
  en: farmerJoeEn, // default language
};
const countries = {
  en: countriesEn,
};
/**
 * If we have a user, check if he has specified a language in his profile
 */
let unsubscribeOnSnapshot: any = null;
firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    unsubscribeOnSnapshot && unsubscribeOnSnapshot();
    unsubscribeOnSnapshot = firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser?.uid)
      .onSnapshot(
        doc => {
          const data = doc.data();

          if (data && data.language) {
            changeLocale(data.language);
          }
        },
        error => console.log("language error", error),
      );
  } else {
    unsubscribeOnSnapshot && unsubscribeOnSnapshot();
  }
});

I18n.fallbacks = true;
I18n.translations = translations;

export default I18n;

/**
 * Translate the string with JSX component for variables
 *
 * @param i18nKey
 * @param components
 * @returns {Array}
 */
export const tWithComponent = (i18nKey: string, components: Array<any>) => {
  let string = I18n.t(i18nKey);
  const results: any = [];
  components.forEach((component, idx) => {
    if (string === undefined) {
      return;
    }
    const splitedStrings = string.split("{" + idx + "}");
    results.push(splitedStrings[0]);
    results.push(component);
    string = splitedStrings[1];
  });
  results.push(string);
  return results;
};

export function browserLanguage() {
  const browserLanguage = String(navigator.language).split("-")[0];
  return isLocaleSupported(browserLanguage) ? browserLanguage : "en";
}

export function browserCountry() {
  return String(navigator.language).split("-")[1] || null;
}

export function getCurrentLocaleData() {
  if (isLocaleLoaded(I18n.locale)) {
    return getLocaleData(I18n.locale);
  } else {
    throw new Error("current locale not loaded, this shouldn't happen");
  }
}

export async function changeLocale(locale: string) {
  if (!isLocaleSupported(locale)) {
    throw new Error(`tried to set locale to ${locale} which isn't supported`);
  }

  if (!isLocaleLoaded(locale)) {
    await loadLocale(locale, ["farmerJoe", "countries", "moment", "calendar"]);
  }
  const {
    farmerJoe,
    countries: countriesData,
    moment: momentLocale,
  } = getLocaleData(locale);
  I18n.locale = locale;
  firebase.auth().languageCode = locale;
  translations[locale] = farmerJoe.default;
  if (momentLocale && momentLocale.default) {
    moment.locale(locale, momentLocale.default);
  }
  moment.locale(locale);
  countries[locale] = countriesData.default;
}

export function getCountries() {
  return countries[I18n.locale] || countries.en;
}
