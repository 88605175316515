import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as fieldActions from "farmerjoe-common/lib/actions/field";
import * as employeeCreators from "farmerjoe-common/lib/actions/employee";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getCompany } from "farmerjoe-common/lib/selectors/companies";
import {
  getCompanyGroupProfileForLoggedInUser,
} from "farmerjoe-common/lib/selectors/user";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";

import { Loading } from "../../components/Loading/Loading";
import EmployeesComponent from "../../components/Employees/Employees";
import useCompanyEmployees from "../../hooks/useCompanyEmployees";

type Props = {
  openCompany: string;
  browsingGroup: string;
};

const Employees = (props: Props) => {
  const { openCompany, browsingGroup } = props;
  const [employees, loading] = useCompanyEmployees(openCompany, browsingGroup, "all");
  if (loading) {
    return <Loading />;
  }
  return <EmployeesComponent {...props} users={employees} />;
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          ...employeeCreators,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const browsingGroup = getBrowsingGroupKey(state, openCompany);

  return {
    auth: state.firebase.auth,
    browsingGroup: browsingGroup,
    company,
    email: state.firebase.profile.email,
    myCompanyProfile,
    openCompany,
  };
};

export default connect(
  selector,
  mapDispatchToProps,
)(Employees);
