import React from "react";
import { get } from "lodash-es";
import { connect } from "react-redux";

import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import {
  DISPLAY_SETTINGS_SHOW_CROPS_WITH,
  DISPLAY_SETTINGS_SHOW_CROPS_WITH_AREA,
  DISPLAY_SETTINGS_SHOW_CROPS_WITH_PIECES,
} from "farmerjoe-common/lib/constants/filters";

import Icon from "../Common/Icon";
import CropIcon from "../Common/CropIcon";
import CropHeaderContainer from "../Common/CropHeaderContainer";
import * as constants from "../../styles/style";
import I18n from "../../language/i18n";
import useNumberFormatter from "../../hooks/useNumberFormatter";


type Props = {
  title: string;
  rowData?: any;
  open?: any;
  showCropPieces?: boolean;
};

const CropHeader = (props: Props) => {
  const { rowData, open, title, showCropPieces } = props;
  const numberFormatter = useNumberFormatter(I18n.locale, 0);

  const crop = rowData.fields[0].activeCrop;

  let headerTitle = title;
  if (title === "without_crop_name" || title === "without_crop_variety") {
    headerTitle = I18n.t(`crop.${title}`);
  }
  if (title === "Ohne Kultur") {
    headerTitle = I18n.t("crop.without");
  }

  return (
    <CropHeaderContainer color={crop.color}>
      <div
        style={{
          ...constants.styles.containerRow,
          ...{ alignItems: "baseline", width: 200 },
        }}>
        <CropIcon crop={crop} />
        <span
          style={{
            ...constants.styles.stdSize,
            ...{
              backgroundColor: "transparent",
              color: "#000",
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "block",
              whiteSpace: "nowrap",
            },
          }}>
          {headerTitle}
        </span>
      </div>
      {showCropPieces ? renderCropPieces(numberFormatter)(rowData.fields) : renderArea(rowData.fields)}
      <div
        style={{
          display: "flex",
          paddingTop: 10,
          paddingBottom: 4,
          width: 50,
          alignItems: "center",
          marginRight: 15,
          flexDirection: "column",
          lineHeight: 1,
        }}>
        <div style={{ backgroundColor: "transparent" }}>
          <span
            style={{
              ...constants.styles.small,
              ...{ backgroundColor: "transparent" },
            }}>
            {rowData.fields.length}
          </span>
        </div>
        <div style={{}}>
          {open
            ? (
              <Icon
                iconType={"fj"}
                style={{ backgroundColor: "transparent", fontSize: 10 }}
                name="arrow_down"
              />
            )
            : (
              <Icon
                iconType={"fj"}
                style={{ backgroundColor: "transparent", fontSize: 10 }}
                name="arrow_up"
              />
            )}
        </div>
      </div>
    </CropHeaderContainer>
  );
};

const renderArea = (fields) => {
  const size = fields
    .map(el => el.size)
    .reduce((pre = 0, cur = 0) => {
      return pre + cur;
    }, 0)
    .toFixed(2);

  return [
    <span
      key="size"
      style={{
        ...constants.styles.stdSize,
        ...{
          width: 100,
          textAlign: "right",
          paddingRight: 2,
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
      }}>
      {size}{" "}
    </span>,
    <span
      key="unit"
      style={{
        ...constants.styles.stdSize,
        ...{
          width: 25,
          textAlign: "left",
          marginRight: 5,
        },
      }}>
          ha
    </span>,
  ];
};

const renderCropPieces = (numberFormatter) => (fields) => {
  const quantity = fields
    .map(el => parseInt(el.activeCrop.pieces))
    .reduce((pre = 0, cur = 0) => {
      if (Number.isNaN(cur)) {
        return pre;
      }
      return pre + cur;
    }, 0);

  return [
    <span
      key="quantity"
      style={{
        ...constants.styles.stdSize,
        ...{
          textAlign: "right",
          paddingRight: 2,
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
      }}>
      {numberFormatter(quantity)}{" "}
    </span>,
    <span
      key="unit"
      style={{
        ...constants.styles.stdSize,
        ...{
          textAlign: "left",
          marginRight: 5,
        },
      }}>
      {I18n.t("crop.pieces.short")}
    </span>,
  ];
};

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const displaySettings = get(
    state,
    ["filtersByCompany", openCompany, "displaySettings"],
    {},
  );

  const currentFieldsTab = get(state, `currentFieldsTab[${openCompany}]`, null);

  const showCropPieces = get(
    displaySettings,
    [
      currentFieldsTab,
      DISPLAY_SETTINGS_SHOW_CROPS_WITH,
    ],
    DISPLAY_SETTINGS_SHOW_CROPS_WITH_AREA,
  ) === DISPLAY_SETTINGS_SHOW_CROPS_WITH_PIECES;

  return {
    showCropPieces,
  };
};

export default connect(selector)(CropHeader);
